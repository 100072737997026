/* styles.css */

.outer-wrapper {
  padding: 20px;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 12px !important;
  font-weight: bold;
  align-items: center;
  box-sizing: border-box;
  color: rgba(14, 34, 56, 0.353);
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.5px;
  margin: 0px 0px 7.5px;
  place-items: center normal;
  text-transform: uppercase;
  word-wrap: break-word;
}

.amount {
  font-size: 24px;
  margin-top: 10px;
  box-sizing: border-box;
  color: #0e2238;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 30.25px;
  word-wrap: break-word;
}

.link {
  margin-top: 10px;
  box-sizing: border-box;
  color: #00959f;
  cursor: pointer;
  display: inline;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 15px;
  margin: 7.5px 0px 0px;
  word-wrap: break-word;
}

.icon {
  font-size: 24px;
  box-sizing: border-box;
  display: inline;
}


@font-face { 
    font-display:block;
    font-family:"bootstrap-icons";
    src:url("https://wtrader.pro/themes/dashly/assets/ext/font/fonts/bootstrap-icons.woff2?1fa40e8900654d2863d011707b9fb6f2") format("woff2"),
  url("https://wtrader.pro/themes/dashly/assets/ext/font/fonts/bootstrap-icons.woff?1fa40e8900654d2863d011707b9fb6f2") format("woff");
  } 
  @font-face { 
    font-display:block;
    font-family:"bootstrap-icons";
    src:url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/fonts/bootstrap-icons.woff2?1fa40e8900654d2863d011707b9fb6f2") format("woff2"),
  url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/fonts/bootstrap-icons.woff?1fa40e8900654d2863d011707b9fb6f2") format("woff");
  } 



  /* Global styles */
.outer-wrapper {
  padding: 20px;
}

.card {
  margin: 10px 0;
}

.legend-circle-sm {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.amount {
  font-size: 1.5rem;
}

.link {
  display: inline-block;
  margin-right: 8px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .col-lg-3 {
    width: 50%;
  }
}

/* Media queries for very small screens */
@media (max-width: 576px) {
  .col-lg-3 {
    width: 100%;
  }
}
