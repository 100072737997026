@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
@font-face {
  font-family: "AdmSymbols";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("https://admiralmarkets.com/build/icons.woff2") format("woff2"),
    url("https://admiralmarkets.com/build/icons.woff") format("woff"),
    url("https://admiralmarkets.com/build/icons.ttf") format("truetype");
}
body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
}
body {
  /* CSS Variables that may have been missed get put on body */
  --content-width: 100%;
  --content-width: 408px;
  --content-width: 606px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
body {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
html {
  box-sizing: border-box;
}

html {
  overflow: initial !important;
  scroll-behavior: smooth;
}

:root {
  --primary-5: #f1f7fe;
  --secondary-5: #f3f5fa;
}

:root {
  --adm-background-default: #f8f8fb;
  --adm-text-title: #181e30;
  --adm-text-primary: #293050;
  --adm-text-accent-hover: #0070ed;
  --adm-button-secondary-default: rgba(24, 30, 48, 0.06);
  --adm-button-secondary-hover: rgba(24, 30, 48, 0.12);
}

*,
:after,
:before,
body,
html {
  box-sizing: border-box;
}

section:not(:first-child) {
  margin-top: 48px;
  margin-bottom: 48px;
}

@media screen and (min-width: 992px) {
  section:not(:first-child) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.commits__item {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .commits__item {
    margin-bottom: 32px;
  }
}

.commit {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  --content-width: 100%;
}

@media screen and (min-width: 768px) {
  .commit {
    display: flex;
    align-items: flex-end;
    --content-width: 408px;
  }
}

@media screen and (min-width: 992px) {
  .commit {
    --content-width: 606px;
  }
}

.commit:nth-child(2n + 1) {
  background-color: var(--primary-5);
}

.commit:nth-child(2n) {
  background-color: var(--secondary-5);
}

@media screen and (min-width: 768px) {
  .commit:nth-child(2n) {
    flex-direction: row-reverse;
  }
}

.commit__content {
  width: var(--content-width);
  position: relative;
  z-index: 1;
  padding: 32px;
}

@media screen and (min-width: 768px) {
  .commit__content {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (min-width: 992px) {
  .commit__content {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (min-width: 768px) {
  .commit:nth-child(2n + 1) .commit__content {
    padding-right: 50px;
    padding-left: 48px;
  }
}

@media screen and (min-width: 992px) {
  .commit:nth-child(2n + 1) .commit__content {
    padding-right: 60px;
    padding-left: 96px;
  }
}

.commit__image {
  padding-top: 32px;
  height: 302px;
}

@media screen and (min-width: 768px) {
  .commit__image {
    height: auto;
    padding-top: 60px;
    width: calc(100% - var(--content-width));
  }
}

@media screen and (min-width: 992px) {
  .commit__image {
    height: 700px;
    padding-top: 80px;
    width: calc(100% - var(--content-width));
  }
}

@media screen and (min-width: 768px) {
  .commit:nth-child(2n) .commit__content {
    padding-left: 50px;
    padding-right: 48px;
  }
}

@media screen and (min-width: 992px) {
  .commit:nth-child(2n) .commit__content {
    padding-left: 60px;
    padding-right: 96px;
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
}

.adm-text--title {
  color: var(--adm-text-title);
}

.adm-typography {
  margin: 0;
  padding: 0;
}

.adm-typography--h1 {
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.47px;
}

.commit__title {
  margin-bottom: 12px;
}

@media screen and (min-width: 992px) {
  .commit__title {
    margin-bottom: 16px;
  }
}

.commit__description {
  margin-bottom: 32px;
}

img {
  border-style: none;
}

.commit__image img {
  height: 300px;
  width: 100%;
  max-width: 300px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: bottom;
  object-position: bottom;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .commit__image img {
    height: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .commit__image img {
    max-width: 452px;
  }
}

@media screen and (min-width: 768px) {
  .commit:nth-child(2n + 1) .commit__image img {
    margin-left: auto;
    margin-right: -15%;
  }
}

@media screen and (min-width: 992px) {
  .commit:nth-child(2n + 1) .commit__image img {
    margin-right: auto;
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .commit:nth-child(2n) .commit__image img {
    margin-left: -15%;
    margin-right: auto;
  }

  .commit:nth-child(2) .commit__image img {
    width: 590px;
    max-width: inherit;
    transform: translateX(-30%);
  }
}

@media screen and (min-width: 992px) {
  .commit:nth-child(2n) .commit__image img {
    margin-right: 0;
    margin-left: auto;
  }

  .commit:nth-child(2) .commit__image img {
    width: 694px;
  }
}

@media screen and (min-width: 1200px) {
  .commit:nth-child(2) .commit__image img {
    transform: translateX(-20%);
  }
}

a {
  background-color: transparent;
}

a {
  color: #237dea;
  text-decoration: none;
}

.adm-button {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background: none;
  transition-property: background-color, color, outline-width;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.adm-button.adm-button--secondary-default {
  color: var(--adm-text-primary);
  background-color: var(--adm-button-secondary-default);
}

.adm-button.adm-button--inline {
  display: inline-flex;
}

.adm-button.adm-button--medium {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: -0.09px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.adm-button.adm-button--medium.adm-button--with-icon-trail {
  padding-left: 12px;
  padding-right: 8px;
}

.adm-button.adm-button--secondary-default:hover {
  color: var(--adm-text-accent-hover);
  background-color: var(--adm-button-secondary-hover);
}

.details-widget__content {
  margin-bottom: 12px;
}

a,
a:visited {
  color: #237dea;
  text-decoration: none;
}

.adm-button.adm-button--secondary-default,
.adm-button.adm-button--secondary-default:visited {
  color: var(--adm-text-primary);
  background-color: var(--adm-button-secondary-default);
}

.adm-text--primary {
  color: var(--adm-text-primary);
}

.adm-typography--bodyL {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.18px;
}

.adm-marketplace {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

.details-widget__details {
  display: inline;
}

.details-widget .details-widget__details[data-v-5eba622c] {
  display: inline;
}

.adm-symbol[data-v-81e1ef1a] {
  font-family: AdmSymbols, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: var(--22881d4d);
  line-height: var(--22881d4d);
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.adm-button .adm-button__icon-trail {
  margin-right: 0;
  margin-left: 8px;
}

.adm-button.adm-button--medium .adm-button__icon {
  font-size: 20px;
}

.details-widget__summary {
  padding-right: 4px;
  padding-left: 0;
}

.details-widget__summary {
  display: inline;
}

.details-widget .details-widget__summary[data-v-5eba622c] {
  padding-right: 4px;
  padding-left: 0;
}

.details-widget .details-widget__summary[data-v-5eba622c] {
  display: inline;
}

strong {
  font-weight: bolder;
}

strong {
  font-weight: 600;
}

.adm-button--market.adm-button--small {
  padding: 12px;
}

.commit__paragraph {
  margin-bottom: 12px;
}

.details-widget__details .commit__paragraph:first-child {
  display: inline;
}

.commit__paragraph:nth-child(2) {
  margin-top: 12px;
}

ul {
  margin: 0;
  padding: 0;
}

.why-us ul {
  padding-left: 17px;
  padding-right: 0;
}

.details-widget .details-widget__summary [data-v-5eba622c] {
  display: inline;
}

.details-widget .commit__summary {
  display: inline;
}

ul li {
  color: rgba(0, 0, 0, 0.6);
  list-style: none;
}

.commit__paragraph li {
  margin-bottom: 12px;
  list-style: disc;
}

/* These were inline style tags. Uses id+class to override almost everything */
#style-alOPY.style-alOPY {
  --22881d4d: 24px;
}
#style-tqZty.style-tqZty {
  --22881d4d: 24px;
}
#style-9NRCq.style-9NRCq {
  --22881d4d: 24px;
}
#style-Vebz8.style-Vebz8 {
  --22881d4d: 24px;
}
#style-vfOIe.style-vfOIe {
  --22881d4d: 24px;
}
#style-zbi2B.style-zbi2B {
  --22881d4d: 24px;
}
