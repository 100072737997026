@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
body {
  font-weight: 400;
  color: #333;
  line-height: 1.375rem;
  font-family: sans-serif;
  font-size: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  font-smooth: never !important;
  -webkit-font-smoothing: antialiased !important;
  text-shadow: none !important;
  -webkit-text-stroke: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
}

body {
  text-rendering: auto;
}

body {
  background-color: #fff;
}

body {
  font-weight: 400;
}

body {
  width: 100%;
}

body {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  color: #333;
  line-height: 1.375rem;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

body {
  font-family: Inter, "Open Sans", sans-serif;
  font-size: 1rem;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  position: relative;
}

body {
  margin: 0;
  font-family: Inter, Open Sans;
}

html {
  -webkit-tap-highlight-color: transparent;
}

html {
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: sans-serif;
}

html {
  width: 100%;
}

html {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

html {
  background-color: #fff;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 100%;
}

html {
  overflow: initial !important;
  scroll-behavior: smooth;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.section-header {
  max-width: 750px;
  margin: 0 auto 64px;
  text-align: center;
}

.ed-levels__items {
  display: flex;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: visible;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container {
  overflow: hidden;
}

.ed-levels-mobile {
  display: none;
  overflow: hidden;
}

h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
}

h1 {
  margin: 0 0 20px;
  padding: 0;
}

h1 {
  font-weight: 400;
  color: #404855;
}

h1 {
  font-size: 3rem;
  line-height: 3.6rem;
}

h1 {
  line-height: 3.6rem;
  font-size: 3rem;
}

h1 {
  font-weight: 500;
}

.section-header__title {
  font-size: 2.25rem;
  line-height: 2.625rem;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.87);
}

p {
  clear: both;
}

p {
  margin: 0 0 20px;
  padding: 0;
}

p {
  font-size: 1rem;
  line-height: 1.6rem;
}

.ed-page p {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.6);
}

p:last-child {
  margin-bottom: 0;
}

.ed-levels__item {
  width: 33.3%;
  padding: 0 16px;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-wrapper {
  transform: translateZ(0);
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.ed-levels__item-inner {
  max-width: 316px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: all;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #f1f2f4;
  height: 644px;
  overflow: hidden;
}

.ed-levels-mobile .swiper-slide {
  background-color: transparent;
  height: auto;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: 0.3s opacity;
  transition: opacity 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 10;
}

.swiper-pagination {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  transform: translateZ(0);
  z-index: 10;
}

.swiper-pagination {
  height: auto;
  bottom: 0;
}

.swiper-container-horizontal .swiper-pagination-bullets {
  width: 100%;
}

.ed-levels-mobile .swiper-pagination {
  height: auto;
  bottom: 0;
}

.ed-levels__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fafafa;
  margin-bottom: 24px;
}

.ed-levels__item-title {
  color: #404855;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.4375rem;
}

.ed-levels-mobile .ed-levels__item {
  width: 100%;
}

img {
  border: 0;
  vertical-align: middle;
}

.ed-levels-mobile .ed-levels__item-title {
  margin-bottom: 32px;
}

/* These were inline style tags. Uses id+class to override almost everything */
#style-EH1iq.style-EH1iq {
  transition-duration: 0ms;
}

/* Tablet view (medium screens) */
@media (max-width: 768px) {
  .ed-levels__item {
    flex: 1 1 45%; /* Flex-basis: 45% */
  }
}

/* Mobile view (small screens) */
@media (max-width: 480px) {
  .ed-levels__item-title {
    font-size: 15px;
  }
}
