@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  body {  
    font-weight:400;
    color:#333;
    line-height:1.375rem;
    font-family:sans-serif;
    font-size:100%;
  }  

@media (min-width: 768px){ 
  .container { 
    width: 750px;
  } 
}     

.container { 
    margin-right: auto; 
    margin-left: auto;
} 

.container { 
    padding-left: 15px; 
    padding-right: 15px;
} 

@media (min-width: 992px){ 
  .container { 
    width: 970px;
  } 
}     

@media (min-width: 1200px){ 
  .container { 
    width: 1170px;
  } 
}     

.container { 
    max-width: 1040px;
} 

@media (min-width: 1200px){ 
  .container { 
    width: 1200px!important; 
    max-width: 1200px!important;
  } 

  .container  { 
    width: 1200px!important; 
    max-width: 1200px!important;
  } 

  .container  { 
    width: 1200px!important; 
    max-width: 1200px!important; 
    margin: 0 auto; 
    position: relative;
  } 
}     

body { 
    -moz-osx-font-smoothing: grayscale;
} 

body { 
    text-rendering: auto;
} 

body { 
    background-color: #fff;
} 

body { 
    font-weight: 400;
} 

body { 
    width: 100%;
} 

body { 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
} 

body { 
    color: #333; 
    line-height: 1.375rem;
} 

body { 
    -webkit-font-smoothing: antialiased; 
    margin: 0; 
    padding: 0;
} 

body { 
    font-family: Inter,'Open Sans',sans-serif; 
    font-size: 1rem; 
    -webkit-box-sizing: inherit; 
    -moz-box-sizing: inherit; 
    box-sizing: inherit; 
    position: relative;
} 

body { 
    margin: 0; 
    font-family: Inter,Open Sans;
} 


.row { 
    margin-left: -15px; 
    margin-right: -15px;
} 

.row { 
    margin-top: 30px;
} 

.row:first-child { 
    margin-top: 0;
} 

.row  { 
    margin-top: 0;
} 

.row:last-child  { 
    margin-top: 0;
} 

:after,:before { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

.container-fluid:after,.container-fluid:before,.container:after,.container:before,.row:after,.row:before { 
    display: table; 
    content: " ";
} 

#navbar.header-navigation-part:after, .btn-group-vertical > .btn-group::after,.btn-toolbar:after, .calendar-wrapper .choice-color-box::after,.check-list-with-img:after,.clearfix:after,.container-fluid:after,.container:after,.custom-pagination-fancy:after,.custom-pagination:after,li > a , .form-horizontal .form-group::after,.header-top-part:after,.icon-border:after,.modal-footer:after,.modal-header:after,.nav-tabs.vertical-tabs:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar-nav:after,.navbar:after,.pager:after,.panel-body:after,.row:after, .slider-calc li::after,.slider-panel:after,.stat-breadcrumbs:after,.testimonials-profile-switch:after,.trigger-table-sub-accordion:after,.trigger-table:after,nav#navbar:after,p { 
    clear: both;
} 

.clear:after,.form-group:after,.pagination:after,.row:after,dl:after,header:after { 
    clear: both; 
    content: ""; 
    display: table;
} 

.p-y-lg { 
    padding-top: 3em!important;
} 

.p-y-lg { 
    padding-bottom: 3em!important;
} 

.p-y-lg { 
    padding-top: 3em !important; 
    padding-bottom: 3em !important;
} 

.online-trading-wrap > div { 
    background-color: #F5F7FA;
} 

#navbar.header-navigation-part::after, .btn-group-vertical > .btn-group::after, .btn-toolbar::after, .calendar-wrapper .choice-color-box::after, .check-list-with-img::after, .clearfix::after, .container-fluid::after, .container::after, .custom-pagination-fancy::after, .custom-pagination::after, .dropdown-menu > li > a, .form-horizontal .form-group::after, .header-top-part::after, .icon-border::after, .modal-footer::after, .modal-header::after, .nav-tabs.vertical-tabs::after, .nav::after, .navbar-collapse::after, .navbar-header::after, .navbar-nav::after, .navbar::after, .pager::after, .panel-body::after, .row::after, .slider-calc li::after, .slider-panel::after, .stat-breadcrumbs::after, .testimonials-profile-switch::after, .trigger-table-sub-accordion::after, .trigger-table::after, nav#navbar::after, p { 
    clear: both;
} 

.text-center { 
    text-align: center;
} 

.m-b-md { 
    margin-bottom: 1.5em!important;
} 

.m-t-lg { 
    margin-top: 3em!important;
} 

.m-b-md { 
    margin-bottom: 1.5em !important;
} 

.m-t-lg { 
    margin-top: 3em !important;
} 

.col-sm-3 { 
    position: relative; 
    min-height: 1px; 
    padding-left: 15px; 
    padding-right: 15px;
} 

@media (min-width: 768px){ 
  .col-sm-3 { 
    float: left;
  } 

  .col-sm-3 { 
    width: 25%;
  } 
}     

a { 
    background-color: transparent;
} 

a { 
    cursor: pointer;
} 

a { 
    text-decoration: none;
} 

a { 
    font-weight: 400;
} 

a { 
    color: #237dea; 
    line-height: 1.1rem; 
    -webkit-transition: 150ms; 
    -moz-transition: 150ms; 
    -o-transition: 150ms; 
    transition: 150ms;
} 



.custom-btn { 
    -webkit-border-radius: 2rem; 
    -moz-border-radius: 2rem; 
    border-radius: 2rem; 
    box-shadow: none; 
    display: inline-block; 
    color: #fff; 
    font-size: 1rem; 
    line-height: 1.1rem; 
    padding: 15px 17px; 
    background: #237dea; 
    font-weight: 600; 
    text-transform: uppercase;
    width: fit-content;
} 

.custom-btn { 
    border-radius: 2rem; 
    box-shadow: none; 
    display: inline-block; 
    color: #fff; 
    font-size: 14px; 
    line-height: 1.1rem; 
    letter-spacing: 1px; 
    text-align: center; 
    vertical-align: middle; 
    font-weight: 700; 
    text-decoration: none;
} 

.custom-btn.btn-large { 
    font-size: 1.2rem; 
    padding: 18px 27px;
} 

a:active,a:hover { 
    outline: 0;
} 

a:hover { 
    text-decoration: none; 
    outline: 0;
} 

a,a:hover { 
    text-decoration: none;
} 

a:hover { 
    color: #1364c7; 
    background: 0 0;
} 

.custom-btn:hover { 
    color: #fff; 
    background: #1364c7;
} 

.custom-btn:hover { 
    background: #1364c7; 
    color: #fff; 
    text-decoration: none;
} 

.m-t-md { 
    margin-top: 1.5em!important;
} 

.m-b-lg { 
    margin-bottom: 3em!important;
} 

.m-t-md { 
    margin-top: 1.5em !important;
} 

.m-b-lg { 
    margin-bottom: 3em !important;
} 

.col-sm-12 { 
    position: relative; 
    min-height: 1px; 
    padding-left: 15px; 
    padding-right: 15px;
} 

@media (min-width: 768px){ 
  .col-sm-12 { 
    float: left;
  } 

  .col-sm-12 { 
    width: 100%;
  } 
}     

.p-a-md { 
    padding: 1.5em!important;
} 

.p-a-md { 
    padding: 1.5em !important;
} 

.online-trading_item { 
    text-align: center; 
    display: block; 
    text-decoration: none;
} 

.online-trading_item  { 
    text-align: center; 
    display: block; 
    text-decoration: none;
} 

p { 
    margin: 0 0 20px; 
    padding: 0;
} 

p { 
    font-size: 1rem; 
    line-height: 1.6rem;
} 

p:last-child { 
    margin-bottom: 0;
} 

.online-trading_item p  { 
    font-size: 16px; 
    font-weight: 400; 
    line-height: 22px; 
    color: #333333;
} 

.online-trading_item p.description  { 
    line-height: 28px !important;
} 


