

.container { 
    max-width: 1172px; 
    padding: 0 16px; 
    margin: 0 auto; 
    width: 100%;
} 





.adm-typography { 
    margin: 0; 
    padding: 0;
} 

.adm-typography--bodyL { 
    font-family: Inter,sans-serif; 
    font-size: 16px; 
    line-height: 24px; 
    font-weight: 400; 
    letter-spacing: -.18px;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__inner { 
    margin-right: -24px; 
    margin-left: -24px;
  } 
}     

section:not(:first-child)  { 
    margin-top: 48px; 
    margin-bottom: 48px;
} 

@media screen and (min-width: 992px){ 
  section:not(:first-child)  { 
    margin-top: 120px; 
    margin-bottom: 120px;
  } 
}     

.advantages-cards-widget__titleset { 
    margin-bottom: 48px; 
    text-align: center;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__titleset { 
    margin-bottom: 96px; 
    padding-right: 24px; 
    padding-left: 24px;
  } 
}     

.swiper-container { 
    margin-left: auto; 
    margin-right: auto; 
    position: relative; 
    overflow: visible; 
    list-style: none; 
    padding: 0; 
    z-index: 1;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__list { 
    display: flex;
  } 
}     

.swiper-container  { 
    overflow: hidden;
} 

h2 { 
    margin-top: 0; 
    margin-bottom: 24px; 
    font-weight: 400;
} 

.adm-typography--displayM { 
    font-family: Inter,sans-serif; 
    font-size: 24px; 
    line-height: 32px; 
    font-weight: 700; 
    letter-spacing: -.47px;
} 

@media (min-width: 1024px){ 
  .adm-typography--displayM { 
    font-family: Inter,sans-serif; 
    font-size: 32px; 
    line-height: 1.25; 
    font-weight: 800; 
    letter-spacing: -.15px;
  } 
}     

.advantages-cards-widget__title  { 
    text-align: initial;
} 

.swiper-wrapper { 
    position: relative; 
    width: 100%; 
    height: 100%; 
    z-index: 1; 
    display: flex; 
    transition-property: transform; 
    box-sizing: content-box;
} 

.swiper-wrapper { 
    transform: translateZ(0);
} 

.swiper-slide { 
    flex-shrink: 0; 
    width: 100%; 
    height: 100%; 
    position: relative; 
    transition-property: all;
} 

.swiper-slide  { 
    height: auto;
} 

.swiper-pagination { 
    height: 56px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    transition: opacity .3s; 
    transform: translateZ(0); 
    z-index: 10;
} 

.advantages-cards-widget__pagination { 
    margin-top: 24px;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__pagination { 
    display: none;
  } 
}     

.swiper-pagination  { 
    height: auto; 
    bottom: 0;
} 

.swiper-container-horizontal .swiper-pagination-bullets  { 
    width: 100%;
} 

.advantages-cards-widget__item { 
    width: 100%;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__item,.swiper-slide .advantages-cards-widget__item  { 
    padding: 24px;
  } 
}     

@media screen and (min-width: 992px){ 
  .advantages-cards-widget__item,.swiper-slide .advantages-cards-widget__item  { 
    padding-right: 48px; 
    padding-left: 24px;
  } 
}     

.swiper-slide .advantages-cards-widget__item  { 
    margin-bottom: 0; 
    padding: 32px; 
    height: 100%;
} 

.swiper-pagination-bullet { 
    display: inline-block; 
    width: 8px; 
    height: 8px; 
    border-radius: 100%; 
    background-color: var(--primary-25);
} 

.swiper-pagination-bullet-active { 
    opacity: 1; 
    background-color: var(--primary-100);
} 

.swiper-pagination-clickable .swiper-pagination-bullet  { 
    cursor: pointer;
} 

.swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet  { 
    margin: 0 4px;
} 

img { 
    border-style: none;
} 

.advantages-cards-widget__image { 
    margin-bottom: 24px;
} 

.adm-text--title { 
    color: var(--adm-text-title);
} 

.adm-typography--h2 { 
    font-size: 18px; 
    letter-spacing: -.26px;
} 

.adm-typography--h2 { 
    font-family: Inter,sans-serif; 
    line-height: 24px; 
    font-weight: 700;
} 

.advantages-cards-widget__subtitle { 
    margin-bottom: 12px;
} 

p { 
    color: rgba(0,0,0,.6); 
    font-size: 1rem; 
    line-height: 1.75rem; 
    margin-bottom: 24px; 
    margin-top: 0;
} 

p:last-child { 
    margin-bottom: 0;
} 


/* These were inline style tags. Uses id+class to override almost everything */
#style-6SNTq.style-6SNTq {  
   transform: translate3d(0px, 0px, 0px);  
}  
#style-2zbv4.style-2zbv4 {  
   width: 396px;  
}  
#style-bkL8a.style-bkL8a {  
   width: 396px;  
}  
#style-ylI9c.style-ylI9c {  
   width: 396px;  
}  
