@font-face { 
    font-family:'AdmSymbols';
    font-style:normal;
    font-weight:400;
    font-display:fallback;
    src:url("https://admiralmarkets.com/build/icons.woff2") format("woff2"), url("https://admiralmarkets.com/build/icons.woff") format("woff"), url("https://admiralmarkets.com/build/icons.ttf") format("truetype");
  } 
  @import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  @font-face { 
    font-family:FontAwesome;
    src:url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.eot?v=4.6.3);
    src:url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"),url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"),url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"),url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"),url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
    font-weight:400;
    font-style:normal;
  } 
  
  @media (min-width: 768px){ 
    .container { 
      width: 750px;
    } 
  }     
  

  
  @media (min-width: 992px){ 
    .container { 
      width: 970px;
    } 
  }     
  
  @media (min-width: 1200px){ 
    .container { 
      width: 1170px;
    } 
  }     
  
  .container { 
      max-width: 1040px;
  } 
  
  @media (min-width: 1200px){ 
    .container { 
      width: 1200px!important; 
      max-width: 1200px!important;
    } 
  
    .container  { 
      width: 1200px!important; 
      max-width: 1200px!important;
    } 
  
    .container  { 
      width: 1200px!important; 
      max-width: 1200px!important; 
      margin: 0 auto; 
      position: relative;
    } 
  }     
  
  body { 
      -moz-osx-font-smoothing: grayscale;
  } 
  
  body { 
      text-rendering: auto;
  } 
  
  body { 
      background-color: #fff;
  } 
  
  body { 
      font-weight: 400;
  } 
  
  body { 
      width: 100%;
  } 
  
  body { 
      top: 0; 
      right: 0; 
      bottom: 0; 
      left: 0;
  } 
  
  body { 
      color: #333; 
      line-height: 1.375rem;
  } 
  
  body { 
      -webkit-font-smoothing: antialiased; 
      margin: 0; 
      padding: 0;
  } 
  
  body { 
      font-family: Inter,'Open Sans',sans-serif; 
      font-size: 1rem; 
      -webkit-box-sizing: inherit; 
      -moz-box-sizing: inherit; 
      box-sizing: inherit; 
      position: relative;
  } 
  
  body { 
      margin: 0; 
      font-family: Inter,Open Sans;
  } 
  
  html { 
      -webkit-tap-highlight-color: transparent;
  } 
  
  html { 
      -moz-osx-font-smoothing: grayscale;
  } 
  
  html { 
      font-family: sans-serif;
  } 
  
  html { 
      width: 100%;
  } 
  
  html { 
      height: 100%;
  } 
  
  html { 
      -webkit-font-smoothing: antialiased; 
      margin: 0; 
      padding: 0;
  } 
  
  html { 
      background-color: #fff; 
      font-size: 16px; 
      -webkit-text-size-adjust: 100%; 
      -ms-text-size-adjust: 100%; 
      min-width: 300px; 
      overflow-x: hidden; 
      overflow-y: scroll; 
      text-rendering: optimizeLegibility; 
      -webkit-box-sizing: inherit; 
      -moz-box-sizing: inherit; 
      box-sizing: inherit;
  } 
  
  html { 
      font-size: 100%;
  } 
  
  html { 
      overflow: initial!important; 
      scroll-behavior: smooth;
  } 
  
  :root { 
      --adm-text-primary: #293050; 
      --adm-text-accent-hover: #0070ed; 
      --adm-button-secondary-default: rgba(24,30,48,0.06); 
      --adm-button-secondary-hover: rgba(24,30,48,0.12); 
  } 
  
  .row:first-child { 
      margin-top: 0;
  } 
  
  .row:last-child  { 
      margin-top: 0;
  } 
  
  :after,:before { 
      -webkit-box-sizing: border-box; 
      -moz-box-sizing: border-box; 
      box-sizing: border-box;
  } 
  
  .container-fluid:after,.container-fluid:before,.container:after,.container:before,.row:after,.row:before { 
      display: table; 
      content: " ";
  } 
  
  #navbar.header-navigation-part:after, .btn-group-vertical > .btn-group::after,.btn-toolbar:after, .calendar-wrapper .choice-color-box::after,.check-list-with-img:after,.clearfix:after,.container-fluid:after,.container:after,.custom-pagination-fancy:after,.custom-pagination:after,li > a , .form-horizontal .form-group::after,.header-top-part:after,.icon-border:after,.modal-footer:after,.modal-header:after,.nav-tabs.vertical-tabs:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar-nav:after,.navbar:after,.pager:after,.panel-body:after,.row:after, .slider-calc li::after,.slider-panel:after,.stat-breadcrumbs:after,.testimonials-profile-switch:after,.trigger-table-sub-accordion:after,.trigger-table:after,nav#navbar:after,p { 
      clear: both;
  } 
  
  .clear:after,.form-group:after,.pagination:after,.row:after,dl:after,header:after { 
      clear: both; 
      content: ""; 
      display: table;
  } 
  
  .am-footer-top-line  { 
      margin-top: 40px; 
      padding-top: 40px; 
      border-top: 1px solid #e3e3ea;
  } 
  
  .am-footer-nav  { 
      display: flex; 
      flex-wrap: wrap;
  } 
  
  .footer-bottom-line { 
      border-top: 1px solid #e3e3ea; 
      padding-top: 48px; 
      margin-top: 48px; 
      display: flex; 
      align-items: center; 
      justify-content: space-between;
  } 
  
  .footer-bottom-line  { 
      border-top: 1px solid #e3e3ea; 
      padding-top: 48px; 
      margin-top: 48px; 
      display: flex; 
      align-items: center; 
      justify-content: space-between;
  } 
  
  .footer-row  { 
      display: flex; 
      flex-direction: row-reverse; 
      align-items: center; 
      justify-content: space-between;
  } 
  
  .am-footer-nav__block  { 
      flex-basis: 50%; 
      padding-right: 40px; 
      padding-left: 0;
  } 
  
  @media screen and (min-width: 544px){ 
    .am-footer-rw .am-footer-info  { 
      display: flex; 
      justify-content: space-between;
    } 
  }     
  
  .esg-footer { 
      display: flex; 
      align-items: center; 
      font-size: 14px; 
      line-height: 24px; 
      color: rgba(0,0,0,.6);
  } 
  
  .esg-footer  { 
      display: flex; 
      align-items: center; 
      font-size: 14px; 
      line-height: 24px; 
      color: rgba(0,0,0,.6);
  } 
  
  .online-trading-wrap > div { 
      background-color: #F5F7FA;
  } 
  
  .footer-socials__list { 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      gap: 10px;
  } 
  
  .footer-socials__list  { 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      gap: 10px;
  } 
  
  .am-footer-nav__block-title  { 
      margin-bottom: 10px;
  } 
  
  .am-footer-nav__block-main  { 
      padding-bottom: 20px;
  } 
  
  #navbar.header-navigation-part::after, .btn-group-vertical > .btn-group::after, .btn-toolbar::after, .calendar-wrapper .choice-color-box::after, .check-list-with-img::after, .clearfix::after, .container-fluid::after, .container::after, .custom-pagination-fancy::after, .custom-pagination::after, .dropdown-menu > li > a, .form-horizontal .form-group::after, .header-top-part::after, .icon-border::after, .modal-footer::after, .modal-header::after, .nav-tabs.vertical-tabs::after, .nav::after, .navbar-collapse::after, .navbar-header::after, .navbar-nav::after, .navbar::after, .pager::after, .panel-body::after, .row::after, .slider-calc li::after, .slider-panel::after, .stat-breadcrumbs::after, .testimonials-profile-switch::after, .trigger-table-sub-accordion::after, .trigger-table::after, nav#navbar::after, p { 
      clear: both;
  } 
  
  p { 
      margin: 0 0 20px; 
      padding: 0;
  } 
  
  p { 
      font-size: 1rem; 
      line-height: 1.6rem;
  } 
  
  .am-footer p { 
      font-size: .875rem;
  } 
  
  .am-footer p { 
      color: rgba(0,0,0,.6);
  } 
  
  .theme-am footer p { 
      font-size: .875rem;
  } 
  
  body.theme-am .am-footer p { 
      font-size: .875rem;
  } 
  
  body.theme-am .am-footer p { 
      color: rgba(0,0,0,.6);
  } 
  
  p:last-child { 
      margin-bottom: 0;
  } 
  
  ul { 
      margin-top: 0;
  } 
  
  ul { 
      margin-bottom: 10px;
  } 
  
  ul { 
      margin: 0; 
      padding: 0;
  } 
  
  ul { 
      list-style: none; 
      padding: 0; 
      line-height: inherit;
  } 
  
  ul { 
      padding: 0; 
      margin: 0 0 25px;
  } 
  
  ul:last-child { 
      margin-bottom: 0;
  } 
  
  @media screen and (min-width: 544px){ 
    .am-footer-rw .am-footer-info ul  { 
      text-align: right;
    } 
  }     
  
  .trustpilot-widget { 
      text-align: center;
  } 
  
  .regulator-switcher  { 
      color: #424559;
  } 
  
  .regulator-switcher[data-v-41b4f31e] { 
      position: relative; 
      cursor: pointer;
  } 
  
  .regulator-switcher--disabled[data-v-41b4f31e] { 
      pointer-events: none; 
      background: transparent;
  } 
  
  .regulator-list[data-v-41b4f31e] { 
      position: absolute; 
      left: 0; 
      top: 100%; 
      width: 100%; 
      max-width: 100%; 
      box-shadow: 0 3px 5px rgba(85,85,85,.1),0 5px 8px rgba(85,85,85,.08),0 1px 14px rgba(85,85,85,.06); 
      z-index: 10; 
      background: #fff; 
      border-radius: 8px; 
      opacity: 0; 
      pointer-events: none; 
      transform: translateY(10px); 
      transition: opacity .3s cubic-bezier(.77,0,.18,1),transform .3s cubic-bezier(.77,0,.18,1);
  } 
  
  .why-choose-am div { 
      color: #fff;
  } 
  
  a { 
      background-color: transparent;
  } 
  
  a { 
      cursor: pointer;
  } 
  
  a { 
      text-decoration: none;
  } 
  
  a { 
      font-weight: 400;
  } 
  
  a { 
      color: #237dea; 
      line-height: 1.1rem; 
      -webkit-transition: 150ms; 
      -moz-transition: 150ms; 
      -o-transition: 150ms; 
      transition: 150ms;
  } 
  
  .am-footer a { 
      color: rgba(0,0,0,.6);
  } 
  
  .theme-am footer a { 
      color: #424559;
  } 
  
  .am-footer-nav__block-title a  { 
      font-weight: 700; 
      font-size: 16px; 
      color: #424559;
  } 
  
  body.theme-am .am-footer a { 
      color: rgba(0,0,0,.6);
  } 
  
  a:active,a:hover { 
      outline: 0;
  } 
  
  a:hover { 
      text-decoration: none; 
      outline: 0;
  } 
  
  a,a:hover { 
      text-decoration: none;
  } 
  
  a:hover { 
      color: #1364c7; 
      background: 0 0;
  } 
  
  .am-footer a:hover { 
      color: #2b79e2;
  } 
  
  .am-footer .am-footer-nav__block-title a:hover { 
      color: inherit;
  } 
  
  body.theme-am .am-footer a:hover { 
      color: #2b79e2;
  } 
  
  body.theme-am .am-footer .am-footer-nav__block-title a:hover { 
      color: inherit;
  } 
  
  .am-footer-nav__sub-links  { 
      margin-right: -6px; 
      margin-left: -6px; 
      display: flex; 
      flex-wrap: wrap;
  } 
  
  a:visited { 
      text-decoration: none; 
      outline: 0;
  } 
  
  .am-footer a, .am-footer a:visited { 
      color: rgba(0,0,0,.6);
  } 
  
  body.theme-am .am-footer a, body.theme-am .am-footer a:visited { 
      color: rgba(0,0,0,.6);
  } 
  
  a:hover,a:visited { 
      text-decoration: none; 
      outline: 0;
  } 
  
  .am-footer a, .am-footer a:visited, .am-footer p { 
      color: rgba(0,0,0,.6);
  } 
  
  body.theme-am .am-footer a, body.theme-am .am-footer a:visited, body.theme-am .am-footer p { 
      color: rgba(0,0,0,.6);
  } 
  
  strong { 
      font-weight: 700;
  } 
  
  strong { 
      font-weight: 600;
  } 
  
  li { 
      margin: 0; 
      padding: 0;
  } 
  
  li { 
      list-style: none; 
      padding: 0; 
      line-height: inherit;
  } 
  
  ul li  { 
      margin: 0 0 10px;
  } 
  
  .am-footer-rw .am-footer-info ul li  { 
      font-size: .875rem; 
      line-height: 2;
  } 
  
  ul li:last-of-type  { 
      margin-bottom: 0;
  } 
  
  .esg-footer a  { 
      display: flex; 
      align-items: center;
  } 
  
  p > a  { 
      font-weight: 600;
  } 
  
  button { 
      color: inherit; 
      font: inherit; 
      margin: 0;
  } 
  
  button { 
      overflow: visible;
  } 
  
  button { 
      text-transform: none;
  } 
  
  button { 
      -webkit-appearance: button; 
      cursor: pointer;
  } 
  
  button { 
      font-family: inherit; 
      font-size: inherit; 
      line-height: inherit;
  } 
  
  button { 
      border: none; 
      -webkit-transition: 150ms; 
      -moz-transition: 150ms; 
      -o-transition: 150ms; 
      transition: 150ms;
  } 
  
  button { 
      border: none;
  } 
  
  .adm-button { 
      justify-content: center; 
      align-items: center; 
      box-sizing: border-box; 
      border: 0; 
      border-radius: 8px; 
      overflow: hidden; 
      text-decoration: none; 
      cursor: pointer; 
      background: none; 
      transition-property: background-color,color,outline-width; 
      transition-duration: .2s; 
      transition-timing-function: ease;
  } 
  
  .adm-button.adm-button--secondary-default { 
      color: var(--adm-text-primary); 
      background-color: var(--adm-button-secondary-default);
  } 
  
  .adm-button.adm-button--block { 
      display: flex; 
      width: 100%;
  } 
  
  .adm-button.adm-button--medium { 
      font-family: Inter,sans-serif; 
      font-size: 14px; 
      line-height: 20px; 
      font-weight: 600; 
      letter-spacing: -.09px; 
      padding-top: 8px; 
      padding-bottom: 8px;
  } 
  
  .regulator-label.adm-button[data-v-41b4f31e] { 
      justify-content: space-between; 
      text-align: inherit; 
      font-weight: 400; 
      border: 1px solid transparent; 
      pointer-events: none;
  } 
  
  .adm-button.adm-button--medium.adm-button--with-icon-trail { 
      padding-left: 12px; 
      padding-right: 8px;
  } 
  
  .adm-symbol[data-v-81e1ef1a] { 
      font-family: AdmSymbols,sans-serif; 
      font-weight: 400; 
      font-style: normal; 
      font-size: var(--22881d4d); 
      line-height: var(--22881d4d); 
      letter-spacing: normal; 
      text-transform: none; 
      display: inline-block; 
      white-space: nowrap; 
      word-wrap: normal; 
      direction: ltr; 
      -webkit-font-feature-settings: "liga"; 
      -webkit-font-smoothing: antialiased;
  } 
  
  .adm-button .adm-button__icon-trail  { 
      margin-right: 0; 
      margin-left: 8px;
  } 
  
  .adm-button.adm-button--medium .adm-button__icon  { 
      font-size: 20px;
  } 
  
  .regulator-item[data-v-41b4f31e] { 
      position: relative; 
      color: rgba(0,0,0,.6);
  } 
  
  .regulator-item[data-v-41b4f31e]:hover { 
      background: rgba(0,0,0,.04);
  } 
  
  .ul-unstyle li { 
      margin-bottom: 15px;
  } 
  
  .ul-unstyle li { 
      font-size: 16px; 
      font-weight: 400; 
      line-height: 28px; 
      color: #424559; 
      padding-left: 26px; 
      position: relative;
  } 
  
  .ul-unstyle.text-white li { 
      color: #fff;
  } 
  
  .fa, .ul-unstyle li::before,body,html { 
      -moz-osx-font-smoothing: grayscale;
  } 
  
  .fa, .ul-unstyle li::before,body { 
      text-rendering: auto;
  } 
  
  .ul-unstyle li::before { 
      content: '\f00c'; 
      position: absolute; 
      left: 0; 
      top: 6px; 
      display: inline-block; 
      font: normal normal normal 14px/1 FontAwesome; 
      font-size: inherit; 
      -webkit-font-smoothing: antialiased;
  } 
  
  .fa, .ul-unstyle li::before, body, html { 
      -moz-osx-font-smoothing: grayscale;
  } 
  
  .fa, .ul-unstyle li::before, body { 
      text-rendering: auto;
  } 
  
  .adm-button.adm-button--icon { 
      padding: 12px;
  } 
  
  .adm-button.adm-button--inline { 
      display: inline-flex;
  } 
  
  .adm-button.adm-button--large { 
      font-family: Inter,sans-serif; 
      font-size: 16px; 
      line-height: 24px; 
      font-weight: 600; 
      letter-spacing: -.18px; 
      padding-top: 12px; 
      padding-bottom: 12px;
  } 
  
  .adm-button.adm-button--round { 
      border-radius: 32px;
  } 
  
  .adm-button.adm-button--secondary-default:hover { 
      color: var(--adm-text-accent-hover); 
      background-color: var(--adm-button-secondary-hover);
  } 
  
  .am-footer-nav__sub-link-item  { 
      margin-right: 6px; 
      margin-left: 6px;
  } 
  
  b { 
      font-weight: 700;
  } 
  
  .link  { 
      font-size: .875rem; 
      line-height: 1rem; 
      padding: .4rem 0; 
      color: #6A6D81; 
      font-weight: 400; 
      text-decoration: none;
  } 
  
  .link  { 
      font-size: .875rem; 
      line-height: 1rem; 
      padding: .4rem 0; 
      color: #6a6d81; 
      font-weight: 400; 
      text-decoration: none; 
      transition: all .3s;
  } 
  
  .theme-am footer .link:active, .theme-am footer .link:hover { 
      text-decoration: underline;
  } 
  
  .theme-am footer .link:active, .theme-am footer .link:focus, .theme-am footer .link:hover { 
      color: #2b79e2; 
      text-decoration: none;
  } 
  
  .theme-am footer .link:active, .theme-am footer .link:focus, .theme-am footer .link:hover { 
      text-decoration: underline;
  } 
  
  img { 
      border: 0; 
      vertical-align: middle;
  } 
  
  .regulator-item-line[data-v-41b4f31e] { 
      display: none; 
      position: absolute; 
      width: 2px; 
      height: 32px; 
      background: #105ef6; 
      left: 6px; 
      top: 8px;
  } 
  
  .regulator-item--active .regulator-item-line[data-v-41b4f31e]  { 
      display: block;
  } 
  
  .adm-text--primary { 
      color: var(--adm-text-primary);
  } 
  
  .adm-typography { 
      margin: 0; 
      padding: 0;
  } 
  
  .adm-typography--bodyL { 
      font-family: Inter,sans-serif; 
      font-size: 16px; 
      line-height: 24px; 
      font-weight: 400; 
      letter-spacing: -.18px;
  } 
  
  .regulator-item a[data-v-41b4f31e]  { 
      cursor: pointer; 
      display: block; 
      width: 100%; 
      padding: 10px 16px; 
      color: rgba(0,0,0,.6);
  } 
  
  .regulator-item:hover a[data-v-41b4f31e] { 
      color: #105ef6;
  } 
  
  .adm-button.adm-button--icon .adm-button__icon  { 
      -webkit-user-select: none; 
      -moz-user-select: none; 
      user-select: none;
  } 
  
  .adm-button.adm-button--large .adm-button__icon  { 
      font-size: 24px;
  } 
  
   a { 
      background-color: transparent;
  } 
  
  .am-footer-nav__sub-link  { 
      font-size: .875rem; 
      line-height: 1rem; 
      transition: all .3s; 
      padding: .2rem 0;
  } 
  
  
  /* These were inline style tags. Uses id+class to override almost everything */
  #style-syfGB.style-syfGB {  
     --22881d4d: 24px;  
  }  
  #style-iPI6n.style-iPI6n {  
     --22881d4d: 24px;  
  }  
  #style-Cln26.style-Cln26 {  
     --22881d4d: 24px;  
  }  
  #style-IZJR9.style-IZJR9 {  
     --22881d4d: 24px;  
  }  
  #style-QLotm.style-QLotm {  
     --22881d4d: 24px;  
  }  
  #style-x1lNN.style-x1lNN {  
     --22881d4d: 24px;  
  }  
  #style-tOUym.style-tOUym {  
     --22881d4d: 24px;  
  }  
  #style-OAqrf.style-OAqrf {  
     font-weight: 600;  
  }  
  #style-qOEUH.style-qOEUH {  
     position: relative;  
  }  
  #style-NdGNe.style-NdGNe {  
     position: relative;  
      height: 45px;  
      width: 100%;  
      border-style: none;  
      display: block;  
      overflow: hidden;  
  }  
  










:after,:before { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

.am-footer-top-line  { 
    margin-top: 40px; 
    padding-top: 40px; 
    border-top: 1px solid #e3e3ea;
} 

@media screen and (min-width: 544px){ 
  .am-footer-rw .am-footer-info  { 
    display: flex; 
    justify-content: space-between;
  } 

  .am-footer-info  { 
    display: flex; 
    justify-content: space-between;
  } 
}     

p { 
    clear: both;
} 

p { 
    margin: 0 0 20px; 
    padding: 0;
} 

p { 
    font-size: 1rem; 
    line-height: 1.6rem;
} 

.am-footer p { 
    font-size: .875rem;
} 

.am-footer p { 
    color: rgba(0,0,0,.6);
} 

.theme-am footer p { 
    font-size: .875rem;
} 

body.theme-am .am-footer p { 
    font-size: .875rem;
} 

body.theme-am .am-footer p { 
    color: rgba(0,0,0,.6);
} 

p:last-child { 
    margin-bottom: 0;
} 

ul { 
    margin-top: 0;
} 

ul { 
    margin-bottom: 10px;
} 

ul { 
    margin: 0; 
    padding: 0;
} 

ul { 
    list-style: none; 
    padding: 0; 
    line-height: inherit;
} 

ul { 
    padding: 0; 
    margin: 0 0 25px;
} 

ul:last-child { 
    margin-bottom: 0;
} 

@media screen and (min-width: 544px){ 
  .am-footer-rw .am-footer-info ul  { 
    text-align: right;
  } 

  .am-footer-info ul  { 
    text-align: right;
  } 
}     

strong { 
    font-weight: 700;
} 

strong { 
    font-weight: 600;
} 

li { 
    margin: 0; 
    padding: 0;
} 

li { 
    list-style: none; 
    padding: 0; 
    line-height: inherit;
} 

ul li  { 
    margin: 0 0 10px;
} 

.am-footer-rw .am-footer-info ul li  { 
    font-size: .875rem; 
    line-height: 2;
} 

.am-footer-info ul li  { 
    font-size: .875rem; 
    line-height: 2;
} 

ul li:last-of-type  { 
    margin-bottom: 0;
} 

b { 
    font-weight: 700;
} 

a { 
    background-color: transparent;
} 

a { 
    cursor: pointer;
} 

a { 
    text-decoration: none;
} 

a { 
    font-weight: 400;
} 

a { 
    color: #237dea; 
    line-height: 1.1rem; 
    -webkit-transition: 150ms; 
    -moz-transition: 150ms; 
    -o-transition: 150ms; 
    transition: 150ms;
} 

.am-footer a { 
    color: rgba(0,0,0,.6);
} 

.theme-am footer a { 
    color: #424559;
} 

.link  { 
    font-size: .875rem; 
    line-height: 1rem; 
    padding: .4rem 0; 
    color: #6A6D81; 
    font-weight: 400; 
    text-decoration: none;
} 

.link  { 
    font-size: .875rem; 
    line-height: 1rem; 
    padding: .4rem 0; 
    color: #6a6d81; 
    font-weight: 400; 
    text-decoration: none; 
    transition: all .3s;
} 

body.theme-am .am-footer a { 
    color: rgba(0,0,0,.6);
} 

a:active,a:hover { 
    outline: 0;
} 

a:hover { 
    text-decoration: none; 
    outline: 0;
} 

a,a:hover { 
    text-decoration: none;
} 

a:hover { 
    color: #1364c7; 
    background: 0 0;
} 

.am-footer a:hover { 
    color: #2b79e2;
} 

.theme-am footer .link:active, .theme-am footer .link:hover { 
    text-decoration: underline;
} 

.theme-am footer .link:active, .theme-am footer .link:focus, .theme-am footer .link:hover { 
    color: #2b79e2; 
    text-decoration: none;
} 

body.theme-am .am-footer a:hover { 
    color: #2b79e2;
} 

.theme-am footer .link:active, .theme-am footer .link:focus, .theme-am footer .link:hover { 
    text-decoration: underline;
} 


/* These were inline style tags. Uses id+class to override almost everything */
#style-Mngyf.style-Mngyf {  
   font-weight: 600;  
}  
