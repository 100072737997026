.header-class {
  padding: 20px;
}

.nav-class {
  /* padding: 0 15px 0 15px !important; */
  padding: 25px;
}

.classWrapper {
  display: flex;
  justify-content: space-between;
}

.ul-class-custom {
  display: flex;
  align-items: center;
}

/* Medium screens */
@media (max-width: 768px) {
  .classWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .classWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
