@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  body {  
    color:#333;
    font-weight:400;
    font-size:16px;
    line-height:22px;
    font-family:Inter,sans-serif;
  }  
* { 
    box-sizing: border-box;
} 

body { 
    margin: 0;
} 

body { 
    color: #333; 
    font-weight: 400; 
    font-size: 16px; 
    line-height: 22px; 
    font-family: Inter,sans-serif; 
    -webkit-text-size-adjust: 100%; 
    -moz-text-size-adjust: 100%; 
    text-size-adjust: 100%; 
    -webkit-font-smoothing: antialiased;
} 

*,body { 
    box-sizing: border-box;
} 

html { 
    line-height: 1.15; 
    -webkit-text-size-adjust: 100%;
} 

html { 
    color: #333; 
    font-weight: 400; 
    font-size: 16px; 
    line-height: 22px; 
    font-family: Inter,sans-serif; 
    -webkit-text-size-adjust: 100%; 
    -moz-text-size-adjust: 100%; 
    text-size-adjust: 100%; 
    -webkit-font-smoothing: antialiased;
} 

*,html { 
    box-sizing: border-box;
} 

html { 
    overflow: initial!important; 
    scroll-behavior: smooth;
} 

.home-page section { 
    padding: 60px 0;
} 

.trade  { 
    overflow: hidden;
} 

*,:after,:before,body,html { 
    box-sizing: border-box;
} 

.container { 
    max-width: 1172px; 
    padding: 0 16px; 
    margin: 0 auto; 
    width: 100%;
} 

h2 { 
    margin-top: 0; 
    margin-bottom: 24px; 
    font-weight: 400;
} 

.home-page h2 { 
    font-size: 3rem; 
    line-height: 3.5rem; 
    margin-bottom: 24px; 
    font-weight: 400; 
    margin-top: 0; 
    color: rgba(0,0,0,.87);
} 

.home-page h2 { 
    color: rgba(0,0,0,.87);
} 

.home-page .trade h2 { 
    margin-bottom: 40px;
} 

@media screen and (min-width: 544px){ 
  .home-page .trade h2 { 
    text-align: center;
  } 
}     

.trade__items  { 
    display: flex; 
    /* margin: -12px -20px;  */
    flex-wrap: wrap; 
    justify-content: center;
} 

.trade__item  { 
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.08),0 2px 6px -1px rgba(0,0,0,.08); 
    border-radius: 8px; 
    margin: 12px 20px; 
    padding: 16px; 
    width: calc(33.3% - 40px); 
    display: flex; 
    align-items: center; 
    position: relative;
} 

.trade__item-image  { 
    margin: 0 8px;
} 

.trade__item-image  { 
    min-width: 74px;
} 

.trade__item-info  { 
    margin: 0 8px;
} 

a { 
    background-color: transparent;
} 

a { 
    color: #237dea; 
    text-decoration: none;
} 

.trade__item a  { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    text-indent: -9999px; 
    overflow: hidden;
} 

img { 
    border-style: none;
} 

.trade__item-image img  { 
    display: block; 
    margin: 0 auto;
} 

strong { 
    font-weight: bolder;
} 

strong { 
    font-weight: 600;
} 

strong  { 
    color: rgba(0,0,0,.87);
} 

.trade__item-info strong  { 
    margin-bottom: 8px; 
    font-size: 1rem; 
    line-height: 1.5rem;
} 

p { 
    color: rgba(0,0,0,.6); 
    font-size: 1rem; 
    line-height: 1.75rem; 
    margin-bottom: 24px; 
    margin-top: 0;
} 

p:last-child { 
    margin-bottom: 0;
} 

.trade__item-info p  { 
    font-size: .875rem; 
    line-height: 1.25rem;
} 




/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .trade__item {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (max-width: 768px) {
    .trade__item {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (max-width: 576px) {
    .trade__item {
        flex: 1 1 100%;
        max-width: 100%;
    }
    .small-screen-trade-title{
        font-size: 35px !important;
    }
}