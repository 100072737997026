@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  body {  
    font-weight:400;
    color:#333;
    line-height:1.375rem;
    font-family:sans-serif;
    font-size:100%;
  }  
* { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

*  { 
    font-smooth: never!important; 
    -webkit-font-smoothing: antialiased!important; 
    text-shadow: none!important; 
    -webkit-text-stroke: 0;
} 

body { 
    -moz-osx-font-smoothing: grayscale;
} 

body { 
    text-rendering: auto;
} 

body { 
    background-color: #fff;
} 

body { 
    font-weight: 400;
} 

body { 
    width: 100%;
} 

body { 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
} 

body { 
    color: #333; 
    line-height: 1.375rem;
} 

body { 
    -webkit-font-smoothing: antialiased; 
    margin: 0; 
    padding: 0;
} 

body { 
    font-family: Inter,'Open Sans',sans-serif; 
    font-size: 1rem; 
    -webkit-box-sizing: inherit; 
    -moz-box-sizing: inherit; 
    box-sizing: inherit; 
    position: relative;
} 

body { 
    margin: 0; 
    font-family: Inter,Open Sans;
} 

html { 
    -webkit-tap-highlight-color: transparent;
} 

html { 
    -moz-osx-font-smoothing: grayscale;
} 

html { 
    font-family: sans-serif;
} 

html { 
    width: 100%;
} 

html { 
    height: 100%;
} 

html { 
    -webkit-font-smoothing: antialiased; 
    margin: 0; 
    padding: 0;
} 

html { 
    background-color: #fff; 
    font-size: 16px; 
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%; 
    min-width: 300px; 
    overflow-x: hidden; 
    overflow-y: scroll; 
    text-rendering: optimizeLegibility; 
    -webkit-box-sizing: inherit; 
    -moz-box-sizing: inherit; 
    box-sizing: inherit;
} 

html { 
    font-size: 100%;
} 

html { 
    overflow: initial!important; 
    scroll-behavior: smooth;
} 

.row { 
    margin-left: -15px; 
    margin-right: -15px;
} 

.row { 
    margin-top: 30px;
} 

.row:first-child { 
    margin-top: 0;
} 

.row  { 
    margin-top: 0;
} 

.row:last-child  { 
    margin-top: 0;
} 

:after,:before { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

.container-fluid:after,.container-fluid:before,.container:after,.container:before,.row:after,.row:before { 
    display: table; 
    content: " ";
} 

#navbar.header-navigation-part:after, .btn-group-vertical > .btn-group::after,.btn-toolbar:after, .calendar-wrapper .choice-color-box::after,.check-list-with-img:after,.clearfix:after,.container-fluid:after,.container:after,.custom-pagination-fancy:after,.custom-pagination:after,.dropdown-menu > li > a, .form-horizontal .form-group::after,.header-top-part:after,.icon-border:after,.modal-footer:after,.modal-header:after,.nav-tabs.vertical-tabs:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar-nav:after,.navbar:after,.pager:after,.panel-body:after,.row:after, .slider-calc li::after,.slider-panel:after,.stat-breadcrumbs:after,.testimonials-profile-switch:after,.trigger-table-sub-accordion:after,.trigger-table:after,nav#navbar:after,p { 
    clear: both;
} 

.clear:after,.form-group:after,.pagination:after,.row:after,dl:after,header:after { 
    clear: both; 
    content: ""; 
    display: table;
} 

.col-sm-10 { 
    position: relative; 
    min-height: 1px; 
    padding-left: 15px; 
    padding-right: 15px;
} 

@media (min-width: 768px){ 
  .col-sm-10 { 
    float: left;
  } 

  .col-sm-10 { 
    width: 83.33333%;
  } 

  .col-sm-offset-1 { 
    margin-left: 8.33333%;
  } 
}     

h1 { 
    font-family: inherit; 
    font-weight: 500; 
    line-height: 1.1; 
    color: inherit;
} 

h1 { 
    margin-top: 20px; 
    margin-bottom: 10px;
} 

h1 { 
    font-size: 36px;
} 

h1 { 
    margin: 0 0 20px; 
    padding: 0;
} 

h1 { 
    font-weight: 400; 
    color: #404855;
} 

h1 { 
    font-size: 3rem; 
    line-height: 3.6rem;
} 

h1 { 
    line-height: 3.6rem; 
    font-size: 3rem;
} 

h1 { 
    font-weight: 500;
} 

.text-center { 
    text-align: center;
} 

.p-t { 
    padding-top: 1em!important;
} 

.p-t { 
    padding-top: 1em !important;
} 

.page-heading { 
    font-size: 34px; 
    font-weight: 700; 
    line-height: 41px;
} 

#navbar.header-navigation-part::after, .btn-group-vertical > .btn-group::after, .btn-toolbar::after, .calendar-wrapper .choice-color-box::after, .check-list-with-img::after, .clearfix::after, .container-fluid::after, .container::after, .custom-pagination-fancy::after, .custom-pagination::after, .dropdown-menu > li > a, .form-horizontal .form-group::after, .header-top-part::after, .icon-border::after, .modal-footer::after, .modal-header::after, .nav-tabs.vertical-tabs::after, .nav::after, .navbar-collapse::after, .navbar-header::after, .navbar-nav::after, .navbar::after, .pager::after, .panel-body::after, .row::after, .slider-calc li::after, .slider-panel::after, .stat-breadcrumbs::after, .testimonials-profile-switch::after, .trigger-table-sub-accordion::after, .trigger-table::after, nav#navbar::after, p { 
    clear: both;
} 

p { 
    margin: 0 0 20px; 
    padding: 0;
} 

p { 
    font-size: 1rem; 
    line-height: 1.6rem;
} 

.p-t-xs { 
    padding-top: 0.5em !important;
} 

.page-description { 
    color: #333333; 
    font-size: 16px; 
    font-weight: 400; 
    line-height: 30px;
} 

p:last-child { 
    margin-bottom: 0;
} 


