@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  body {  
    font-weight:400;
    color:#333;
    line-height:1.375rem;
    font-family:sans-serif;
    font-size:100%;
  }  
body { 
/* CSS Variables that may have been missed get put on body */ 
    --mdc-ripple-fg-size:  0;  
    --mdc-ripple-fg-scale:  1;  
} 

* { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

*  { 
    font-smooth: never!important; 
    -webkit-font-smoothing: antialiased!important; 
    text-shadow: none!important; 
    -webkit-text-stroke: 0;
} 

@media (min-width: 768px){ 
  .container { 
    width: 750px;
  } 
}     

.container { 
    margin-right: auto; 
    margin-left: auto;
} 

.container { 
    padding-left: 15px; 
    padding-right: 15px;
} 

@media (min-width: 992px){ 
  .container { 
    width: 970px;
  } 
}     

@media (min-width: 1200px){ 
  .container { 
    width: 1170px;
  } 
}     

.container { 
    max-width: 1040px;
} 

@media (min-width: 1200px){ 
  .container { 
    width: 1200px!important; 
    max-width: 1200px!important;
  } 

  .container  { 
    width: 1200px!important; 
    max-width: 1200px!important;
  } 

  .container  { 
    width: 1200px!important; 
    max-width: 1200px!important; 
    margin: 0 auto; 
    position: relative;
  } 
}     

.container  { 
    max-width: 1172px!important; 
    width: 100%!important; 
    padding: 0 16px; 
    margin: 0 auto;
} 

body { 
    -moz-osx-font-smoothing: grayscale;
} 

body { 
    text-rendering: auto;
} 

body { 
    background-color: #fff;
} 

body { 
    font-weight: 400;
} 

body { 
    width: 100%;
} 

body { 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
} 

body { 
    color: #333; 
    line-height: 1.375rem;
} 

body { 
    -webkit-font-smoothing: antialiased; 
    margin: 0; 
    padding: 0;
} 

body { 
    font-family: Inter,'Open Sans',sans-serif; 
    font-size: 1rem; 
    -webkit-box-sizing: inherit; 
    -moz-box-sizing: inherit; 
    box-sizing: inherit; 
    position: relative;
} 

body { 
    margin: 0; 
    font-family: Inter,Open Sans;
} 

html { 
    -webkit-tap-highlight-color: transparent;
} 

html { 
    -moz-osx-font-smoothing: grayscale;
} 

html { 
    font-family: sans-serif;
} 

html { 
    width: 100%;
} 

html { 
    height: 100%;
} 

html { 
    -webkit-font-smoothing: antialiased; 
    margin: 0; 
    padding: 0;
} 

html { 
    background-color: #fff; 
    font-size: 16px; 
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%; 
    min-width: 300px; 
    overflow-x: hidden; 
    overflow-y: scroll; 
    text-rendering: optimizeLegibility; 
    -webkit-box-sizing: inherit; 
    -moz-box-sizing: inherit; 
    box-sizing: inherit;
} 

html { 
    font-size: 100%;
} 

html { 
    overflow: initial!important; 
    scroll-behavior: smooth;
} 

:after,:before { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

.section-header  { 
    max-width: 750px; 
    margin: 0 auto 64px; 
    text-align: center;
} 

.container  { 
    max-width: 1472px!important; 
    overflow: hidden;
} 

.ed-courses__items  { 
    display: flex;
} 

.ed-courses-reverse  { 
    flex-direction: row-reverse;
} 

.section-header__title  { 
    font-size: 2.25rem; 
    line-height: 2.625rem; 
    margin-bottom: 24px; 
    color: rgba(0,0,0,.87);
} 

p { 
    clear: both;
} 

p { 
    margin: 0 0 20px; 
    padding: 0;
} 

p { 
    font-size: 1rem; 
    line-height: 1.6rem;
} 

.ed-page p { 
    font-size: 1rem; 
    line-height: 1.5rem; 
    letter-spacing: .5px; 
    margin-bottom: 24px; 
    color: rgba(0,0,0,.6);
} 

.ed-courses__item  { 
    width: 50%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    min-height: 500px; 
    position: relative;
} 

.ed-courses__image  { 
    padding: 0 16px;
} 

.ed-courses-reverse .ed-courses__item  { 
    align-items: center;
} 

.ed-courses-reverse .ed-courses__image  { 
    padding: 0 16px;
} 

img { 
    border: 0; 
    vertical-align: middle;
} 

.ed-courses__image img  { 
    top: 0; 
    right: 0; 
    position: absolute; 
    height: 100%;
} 

.ed-courses__item-inner  { 
    max-width: 500px;
} 

.ed-courses__image-title  { 
    position: relative; 
    width: 100%; 
    text-align: center; 
    padding: 0 20px; 
    z-index: 1;
} 

h3 { 
    font-family: inherit; 
    font-weight: 500; 
    line-height: 1.1; 
    color: inherit;
} 

h3 { 
    margin-top: 20px; 
    margin-bottom: 10px;
} 

h3 { 
    font-size: 24px;
} 

h3 { 
    margin: 0 0 20px; 
    padding: 0;
} 

h3 { 
    font-weight: 400; 
    color: #404855;
} 

h3 { 
    font-size: 1.7rem; 
    line-height: 2.2rem;
} 

h3 { 
    line-height: 2.2rem; 
    font-size: 1.7rem;
} 

h3 { 
    font-weight: 500;
} 

.ed-page h3 { 
    font-size: 1.5rem; 
    line-height: 2rem; 
    margin-bottom: 16px;
} 

.ed-page h3 { 
    color: rgba(0,0,0,.87);
} 

.ed-courses__item-inner p  { 
    color: rgba(0,0,0,.87);
} 

a { 
    background-color: transparent;
} 

a { 
    cursor: pointer;
} 

a { 
    text-decoration: none;
} 

a { 
    font-weight: 400;
} 

a { 
    color: #237dea; 
    line-height: 1.1rem; 
    -webkit-transition: 150ms; 
    -moz-transition: 150ms; 
    -o-transition: 150ms; 
    transition: 150ms;
} 

.mdc-button { 
    font-family: Roboto,sans-serif; 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-font-smoothing: antialiased; 
    font-size: .875rem; 
    line-height: inherit; 
    letter-spacing: .0892857143em; 
    text-decoration: none; 
    padding: 0 8px 0 8px; 
    display: -webkit-inline-box; 
    display: -ms-inline-flexbox; 
    display: inline-flex; 
    position: relative; 
    -webkit-box-align: center; 
    -ms-flex-align: center; 
    align-items: center; 
    -webkit-box-pack: center; 
    -ms-flex-pack: center; 
    justify-content: center; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
    min-width: 64px; 
    border: none; 
    outline: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    -webkit-appearance: none; 
    overflow: visible; 
    vertical-align: middle; 
    border-radius: 4px;
} 

.mdc-button--outlined { 
    border-style: solid; 
    padding: 0 15px 0 15px; 
    border-width: 1px;
} 

.mdc-button { 
    --mdc-ripple-fg-size: 0; 
    --mdc-ripple-fg-scale: 1; 
    -webkit-tap-highlight-color: rgba(0,0,0,0);
} 

.mdc-button { 
    height: 36px; 
    font-size: 14px; 
    line-height: normal; 
    letter-spacing: 1.25px; 
    text-transform: uppercase; 
    font-style: normal; 
    font-weight: 500; 
    font-family: Inter;
} 

.mdc-button--large { 
    padding: 0 24px 0 24px; 
    height: 48px;
} 

.mdc-button:not(:disabled) { 
    background-color: transparent;
} 

.mdc-button:not(:disabled) { 
    color: #105ef6; 
    color: var(--mdc-theme-primary,#105ef6);
} 

.mdc-button--outlined:not(:disabled) { 
    border-color: #105ef6; 
    border-color: var(--mdc-theme-primary,#105ef6);
} 

.mdc-button--color-primary:not(:disabled) { 
    color: #105ef6; 
    background-color: transparent;
} 

.mdc-button--color-primary.mdc-button--outlined:not(:disabled) { 
    border-color: #105ef6;
} 

a:active,a:hover { 
    outline: 0;
} 

a:hover { 
    text-decoration: none; 
    outline: 0;
} 

a,a:hover { 
    text-decoration: none;
} 

a:hover { 
    color: #1364c7; 
    background: 0 0;
} 

.mdc-button:hover { 
    cursor: pointer;
} 

.ed-courses__image-title span  { 
    font-size: 5rem; 
    line-height: 5.875rem; 
    color: #105ef6; 
    text-shadow: 0 1px 2px rgba(0,0,0,.08); 
    font-weight: 900;
} 

.mdc-button .mdc-button__ripple  { 
    border-radius: 4px;
} 

.mdc-button--outlined .mdc-button__ripple  { 
    top: -1px; 
    left: -1px; 
    border: 1px solid transparent;
} 

.mdc-button .mdc-button__ripple  { 
    position: absolute; 
    -webkit-box-sizing: content-box; 
    box-sizing: content-box; 
    width: 100%; 
    height: 100%; 
    overflow: hidden;
} 

.mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
    position: absolute; 
    border-radius: 50%; 
    opacity: 0; 
    pointer-events: none; 
    content: "";
} 

.mdc-button .mdc-button__ripple::before { 
    -webkit-transition: opacity 15ms linear,background-color 15ms linear; 
    transition: opacity 15ms linear,background-color 15ms linear; 
    z-index: 1;
} 

.mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
    top: -50%; 
    left: -50%; 
    width: 200%; 
    height: 200%;
} 

.mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
    background-color: #105ef6;
} 

.mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
    background-color: var(--mdc-theme-primary,#105ef6);
} 

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before { 
    -webkit-transform: scale(var(--mdc-ripple-fg-scale,1)); 
    transform: scale(var(--mdc-ripple-fg-scale,1));
} 

.mdc-button--color-primary.mdc-button--outlined .mdc-button__ripple::after, .mdc-button--color-primary.mdc-button--outlined .mdc-button__ripple::before, .mdc-button--color-primary.mdc-button--text .mdc-button__ripple::after, .mdc-button--color-primary.mdc-button--text .mdc-button__ripple::before { 
    background-color: #105ef6;
} 

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after { 
    top: 0; 
    left: 0; 
    -webkit-transform: scale(0); 
    transform: scale(0); 
    -webkit-transform-origin: center center; 
    transform-origin: center center;
} 

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after { 
    width: var(--mdc-ripple-fg-size,100%); 
    height: var(--mdc-ripple-fg-size,100%);
} 

.mdc-button:hover .mdc-button__ripple::before { 
    opacity: .04;
} 



/* Media Query for smaller screens */
@media (max-width: 768px) {
    .ed-courses__items {
      display: block;
    }
    
    .ed-courses__item {
      width: 100%;
    }
  
    .ed-courses__image {
      order: -1; /* Move image to the top */
    }
  
    .ed-courses__item-inner {
      padding: 20px;
    }

    .crypto-small-scren{
        font-size: 50px !important;
    }
  }

