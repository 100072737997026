.input-wrapper-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.input-wrapper-some {
    width: calc(33.33% - 10px); /* Adjust the width as needed */
}

.input-wrapper-main1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.input-wrapper-some1 {
    width: calc(50% - 10px); /* Adjust the width as needed */
}
