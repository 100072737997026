@font-face { 
    font-family:'AdmSymbols';
    font-style:normal;
    font-weight:400;
    font-display:fallback;
    src:url("https://admiralmarkets.com/build/icons.woff2") format("woff2"), url("https://admiralmarkets.com/build/icons.woff") format("woff"), url("https://admiralmarkets.com/build/icons.ttf") format("truetype");
  } 
  @import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
    body {  
      color:#333;
      font-weight:400;
      font-size:16px;
      line-height:22px;
      font-family:Inter,sans-serif;
    }  
  body { 
  /* CSS Variables that may have been missed get put on body */ 
      --mdc-ripple-fg-size:  0;  
      --mdc-ripple-fg-scale:  1;  
  } 
  
  * { 
      box-sizing: border-box;
  } 
  
  body { 
      margin: 0;
  } 
  
  body { 
      color: #333; 
      font-weight: 400; 
      font-size: 16px; 
      line-height: 22px; 
      font-family: Inter,sans-serif; 
      -webkit-text-size-adjust: 100%; 
      -moz-text-size-adjust: 100%; 
      text-size-adjust: 100%; 
      -webkit-font-smoothing: antialiased;
  } 
  
  *,body { 
      box-sizing: border-box;
  } 
  
  html { 
      line-height: 1.15; 
      -webkit-text-size-adjust: 100%;
  } 
  
  html { 
      color: #333; 
      font-weight: 400; 
      font-size: 16px; 
      line-height: 22px; 
      font-family: Inter,sans-serif; 
      -webkit-text-size-adjust: 100%; 
      -moz-text-size-adjust: 100%; 
      text-size-adjust: 100%; 
      -webkit-font-smoothing: antialiased;
  } 
  
  *,html { 
      box-sizing: border-box;
  } 
  
  html { 
      overflow: initial!important; 
      scroll-behavior: smooth;
  } 
  
  *,:after,:before,body,html { 
      box-sizing: border-box;
  } 
  
  .container { 
      max-width: 1172px; 
      padding: 0 16px; 
      margin: 0 auto; 
      width: 100%;
  } 
  
  .choose__items  { 
      margin-left: -18px; 
      margin-right: -18px; 
      display: flex;
  } 
  
  .choose__items-mobile  { 
      display: none; 
      overflow: hidden;
  } 
  
  .choose__item  { 
      width: calc(25% - 36px); 
      padding: 44px 24px 24px; 
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.08),0 2px 6px -1px rgba(0,0,0,.08); 
      border-radius: 8px; 
      margin: 0 18px; 
      display: flex; 
      flex-direction: column; 
      align-items: center;
  } 
  
  .swiper-pagination { 
      height: 56px; 
      display: flex; 
      align-items: center; 
      justify-content: center; 
      transition: opacity .3s; 
      transform: translateZ(0); 
      z-index: 10;
  } 
  
  .swiper-pagination  { 
      height: auto; 
      bottom: 0;
  } 
  
  .choose__items-mobile .swiper-pagination  { 
      height: auto; 
      position: relative; 
      margin-top: 10px; 
      bottom: 0;
  } 
  
  h3 { 
      margin-top: 0; 
      margin-bottom: 24px; 
      font-weight: 400;
  } 
  
  h3 { 
      font-size: 2.25rem; 
      line-height: 2.625rem;
  } 
  
  .home-page h3 { 
      font-size: 1.5rem; 
      line-height: 2rem; 
      margin-bottom: 16px; 
      font-weight: 500; 
      color: rgba(0,0,0,.87);
  } 
  
  .home-page h3 { 
      color: rgba(0,0,0,.87);
  } 
  
  .choose__item-title  { 
      text-align: center;
  } 
  
  p { 
      color: rgba(0,0,0,.6); 
      font-size: 1rem; 
      line-height: 1.75rem; 
      margin-bottom: 24px; 
      margin-top: 0;
  } 
  
  p:last-child { 
      margin-bottom: 0;
  } 
  
  .choose__item p  { 
      text-align: center;
  } 
  
  .choose__item-image  { 
      width: 58px; 
      height: 48px; 
      margin-bottom: 24px; 
      display: flex; 
      justify-content: center; 
      align-items: center;
  } 
  
  a { 
      background-color: transparent;
  } 
  
  a { 
      color: #237dea; 
      text-decoration: none;
  } 
  
  .action-link { 
      margin-top: 8px;
  } 
  
  .mdc-button { 
      font-family: Roboto,sans-serif; 
      -moz-osx-font-smoothing: grayscale; 
      -webkit-font-smoothing: antialiased; 
      font-size: .875rem; 
      line-height: inherit; 
      letter-spacing: .0892857143em; 
      text-decoration: none; 
      padding: 0 8px 0 8px; 
      display: -webkit-inline-box; 
      display: -ms-inline-flexbox; 
      display: inline-flex; 
      position: relative; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      -webkit-box-pack: center; 
      -ms-flex-pack: center; 
      justify-content: center; 
      -webkit-box-sizing: border-box; 
      box-sizing: border-box; 
      min-width: 64px; 
      border: none; 
      outline: none; 
      -webkit-user-select: none; 
      -moz-user-select: none; 
      -ms-user-select: none; 
      user-select: none; 
      -webkit-appearance: none; 
      overflow: visible; 
      vertical-align: middle; 
      border-radius: 4px;
  } 
  
  .mdc-button { 
      --mdc-ripple-fg-size: 0; 
      --mdc-ripple-fg-scale: 1; 
      -webkit-tap-highlight-color: rgba(0,0,0,0);
  } 
  
  .mdc-button { 
      height: 36px; 
      font-size: 14px; 
      line-height: normal; 
      letter-spacing: 1.25px; 
      text-transform: uppercase; 
      font-style: normal; 
      font-weight: 500; 
      font-family: Inter;
  } 
  
  .mdc-button--large { 
      padding: 0 24px 0 24px; 
      height: 48px;
  } 
  
  .mdc-button:not(:disabled) { 
      background-color: transparent;
  } 
  
  .mdc-button:not(:disabled) { 
      color: #105ef6; 
      color: var(--mdc-theme-primary,#105ef6);
  } 
  
  .mdc-button--color-positive:not(:disabled) { 
      color: #23b064; 
      background-color: transparent;
  } 
  
  .choose__item.choose-green a  { 
      color: #00b65d; 
      font-size: .875rem; 
      text-transform: uppercase; 
      line-height: 1rem; 
      font-weight: 500;
  } 
  
  .mdc-button:hover { 
      cursor: pointer;
  } 
  
  .choose__items-mobile .choose__item  { 
      width: auto; 
      margin: 30px 12px; 
      max-width: calc(100% - 24px); 
      height: calc(100% - 60px); 
      box-shadow: 0 8px 16px 0 rgba(0,0,0,.1);
  } 
  
  img { 
      border-style: none;
  } 
  
  .mdc-button .mdc-button__ripple  { 
      border-radius: 4px;
  } 
  
  .mdc-button .mdc-button__ripple  { 
      position: absolute; 
      -webkit-box-sizing: content-box; 
      box-sizing: content-box; 
      width: 100%; 
      height: 100%; 
      overflow: hidden;
  } 
  
  .mdc-button:not(.mdc-button--outlined) .mdc-button__ripple  { 
      top: 0; 
      left: 0;
  } 
  
  .mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
      position: absolute; 
      border-radius: 50%; 
      opacity: 0; 
      pointer-events: none; 
      content: "";
  } 
  
  .mdc-button .mdc-button__ripple::before { 
      -webkit-transition: opacity 15ms linear,background-color 15ms linear; 
      transition: opacity 15ms linear,background-color 15ms linear; 
      z-index: 1;
  } 
  
  .mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
      top: -50%; 
      left: -50%; 
      width: 200%; 
      height: 200%;
  } 
  
  .mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
      background-color: #105ef6;
  } 
  
  .mdc-button .mdc-button__ripple::after, .mdc-button .mdc-button__ripple::before { 
      background-color: var(--mdc-theme-primary,#105ef6);
  } 
  
  .mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before { 
      -webkit-transform: scale(var(--mdc-ripple-fg-scale,1)); 
      transform: scale(var(--mdc-ripple-fg-scale,1));
  } 
  
  .mdc-button--color-positive.mdc-button--outlined .mdc-button__ripple::after, .mdc-button--color-positive.mdc-button--outlined .mdc-button__ripple::before, .mdc-button--color-positive.mdc-button--text .mdc-button__ripple::after, .mdc-button--color-positive.mdc-button--text .mdc-button__ripple::before { 
      background-color: #23b064;
  } 
  
  .mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after { 
      top: 0; 
      left: 0; 
      -webkit-transform: scale(0); 
      transform: scale(0); 
      -webkit-transform-origin: center center; 
      transform-origin: center center;
  } 
  
  .mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after { 
      width: var(--mdc-ripple-fg-size,100%); 
      height: var(--mdc-ripple-fg-size,100%);
  } 
  
  .mdc-button:hover .mdc-button__ripple::before { 
      opacity: .04;
  } 
  
  .material-icons { 
      font-family: AdmSymbols; 
      font-weight: 400; 
      font-style: normal; 
      font-size: 24px; 
      line-height: 1; 
      letter-spacing: normal; 
      text-transform: none; 
      display: inline-block; 
      white-space: nowrap; 
      word-wrap: normal; 
      direction: ltr; 
      -webkit-font-feature-settings: "liga"; 
      -webkit-font-smoothing: antialiased;
  } 
  
  .mdc-button .mdc-button__icon  { 
      margin-left: 0; 
      margin-right: 8px; 
      display: inline-block; 
      width: 18px; 
      height: 18px; 
      font-size: 18px; 
      vertical-align: top;
  } 
  
  .mdc-button__label + .mdc-button__icon  { 
      margin-left: 8px; 
      margin-right: 0;
  } 
  
  .mdc-button--trailing-icon.mdc-button__icon { 
      margin-right: 0; 
      margin-left: 8px;
  } 
  
  .choose__items-mobile h3  { 
      margin-bottom: 2px;
  } 
  
  .choose__items-mobile .choose__item p  { 
      line-height: 1.25rem;
  } 
  
  
  




  /* Responsive Styles */
  /* General Styles */
.choose__item {
    padding: 16px;
    margin: 16px;
    border: 0.5px solid #ccc;
    border-radius: 8px;
    text-align: center;
}

.choose__item img {
    max-width: 100%;
    height: auto;
}

.choose__items-desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.choose__items-mobile {
    display: none;
}

/* Medium screens */
@media (max-width: 768px) {
    .choose__items-desktop {
        display: none;
    }

    .choose__items-mobile {
        display: block;
    }

    .choose__item {
        margin: 8px 0;
    }
    .choose__item-p {
        padding-top: 10px;
        line-height: 2.0rem !important;
        font-size: 16px;
    }
}

/* Small screens */
@media (max-width: 576px) {
    .choose__items-desktop {
        display: none;
    }

    .choose__items-mobile {
        display: block;
    }

    .choose__item {
        margin: 8px 0;
    }
    .choose__item-p {
        padding-top: 10px;
        line-height: 2.0rem !important;
        font-size: 16px;
    }
}
