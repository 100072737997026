@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap'); 
  body {  
    color:#333;
    font-weight:400;
    font-size:16px;
    line-height:22px;
    font-family:Inter,sans-serif;
  }  
* { 
    box-sizing: border-box;
} 

body { 
    margin: 0;
} 

body { 
    color: #333; 
    font-weight: 400; 
    font-size: 16px; 
    line-height: 22px; 
    font-family: Inter,sans-serif; 
    -webkit-text-size-adjust: 100%; 
    -moz-text-size-adjust: 100%; 
    text-size-adjust: 100%; 
    -webkit-font-smoothing: antialiased;
} 

*,body { 
    box-sizing: border-box;
} 

html { 
    line-height: 1.15; 
    -webkit-text-size-adjust: 100%;
} 

html { 
    color: #333; 
    font-weight: 400; 
    font-size: 16px; 
    line-height: 22px; 
    font-family: Inter,sans-serif; 
    -webkit-text-size-adjust: 100%; 
    -moz-text-size-adjust: 100%; 
    text-size-adjust: 100%; 
    -webkit-font-smoothing: antialiased;
} 

*,html { 
    box-sizing: border-box;
} 

html { 
    overflow: initial!important; 
    scroll-behavior: smooth;
} 

:root { 
    --adm-background-default: #f8f8fb; 
    --adm-text-title: #181e30; 
    --adm-text-primary: #293050; 
} 

*,:after,:before,body,html { 
    box-sizing: border-box;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__inner { 
    margin-right: -24px; 
    margin-left: -24px;
  } 
}     

section:not(:first-child)  { 
    margin-top: 48px; 
    margin-bottom: 48px;
} 

@media screen and (min-width: 992px){ 
  section:not(:first-child)  { 
    margin-top: 120px; 
    margin-bottom: 120px;
  } 
}     

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__list { 
    display: flex;
  } 
}     

.adm-text--primary { 
    color: var(--adm-text-primary);
} 

.adm-typography { 
    margin: 0; 
    padding: 0;
} 

.adm-typography--bodyL { 
    font-family: Inter,sans-serif; 
    font-size: 16px; 
    line-height: 24px; 
    font-weight: 400; 
    letter-spacing: -.18px;
} 

.advantages-cards-widget__item { 
    width: 100%;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__item { 
    padding: 24px;
  } 
}     

@media screen and (min-width: 992px){ 
  .advantages-cards-widget__item { 
    padding-right: 48px; 
    padding-left: 24px;
  } 
}     

p { 
    color: rgba(0,0,0,.6); 
    font-size: 1rem; 
    line-height: 1.75rem; 
    margin-bottom: 24px; 
    margin-top: 0;
} 

*  { 
    font-family: Inter,sans-serif; 
    font-size: 16px; 
    line-height: 24px; 
    font-weight: 400; 
    letter-spacing: -.18px;
} 

p:last-child { 
    margin-bottom: 0;
} 

.adaptive-hero-banner__description p { 
    margin-bottom: 16px;
} 

*  { 
    color: var(--adm-text-primary);
} 

img { 
    border-style: none;
} 

.advantages-cards-widget__image { 
    margin-bottom: 24px;
} 

.adm-text--title { 
    color: var(--adm-text-title);
} 

.adm-typography--h2 { 
    font-size: 18px; 
    letter-spacing: -.26px;
} 

.adm-typography--h2 { 
    font-family: Inter,sans-serif; 
    line-height: 24px; 
    font-weight: 700;
} 

.advantages-cards-widget__subtitle { 
    margin-bottom: 12px;
} 

@media screen and (min-width: 768px){ 
  .advantages-cards-widget__item,.advantages-cards-widget__item  { 
    padding: 24px;
  } 
}     

@media screen and (min-width: 992px){ 
  .advantages-cards-widget__item,.advantages-cards-widget__item  { 
    padding-right: 48px; 
    padding-left: 24px;
  } 
}     

.advantages-cards-widget__item  { 
    margin-bottom: 0; 
    padding: 32px; 
    height: 100%;
} 


