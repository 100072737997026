.hero-section {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100vw !important;
  background-color: #f8f8fb !important;
}

.hero-inner {
  width: 80%;
  padding: 50px;
  border-radius: 10px;
}

.content-div {
  align-self: center;
  text-align: start;
  width: 45%;
}

.heading-css {
  font-size: 60px;
  font-weight: 900;
}

.p-class {
  font-size: 20px;
}

.signup-button {
  padding: 10px 30px 10px 30px !important;
  background-color: lightskyblue !important;
  color: white !important;
  height: 60px !important;
  width: 220px !important;
  display: flex;
  justify-content: space-between;
}

.margin-top-5 {
  margin-top: 46px;
}
/* Section 2 */

.hero-section2 {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  /* margin-left: 30px;
    margin-right: 30px; */
  text-align: center;
  width: 100vw !important;
}

.hero-inner2 {
  width: 80%;
  padding: 0px 50px 0px 50px;
  border-radius: 10px;
}

.content-div2 {
  text-align: start;
  width: 45%;
}

.heading-css2 {
  font-size: 50px;
  font-weight: 400;
}

.p-class2 {
  font-size: 20px;
  color: rgb(152, 152, 152);
}
.p-class2-2 {
  font-size: 20px;
  font-weight: 500;
}

.starrt-button {
  padding: 10px 30px 10px 30px !important;
  background-color: rgb(19, 103, 156) !important;
  color: white !important;
  height: 60px !important;
  width: 220px !important;
  display: flex;
  justify-content: space-between;
}

/* Section 5 */
.section5 {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  /* margin-left: 30px;
    margin-right: 30px; */
  text-align: center;
  width: 100vw !important;
}

/* Media Queries */

/* Media queries for responsive design */
@media (max-width: 10) {
}
@media (max-width: 992px) {
  .heading-css {
    font-size: 40px !important;
  }
}

@media (max-width: 768px) {
  .hero-inner {
    display: block !important;
    padding: 50px 0px 50px 0px;
  }
  .content-div {
    width: 100% !important;
  }
  .heading-css {
    font-size: 50px;
  }
  .p-class {
    font-size: 22px;
  }
  .class-link {
    display: flex;
    justify-content: center;
  }
  .margin-top-5 {
    margin-top: 10px;
  }
  .image-div {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }

  /* Section 2 */
  .hero-inner2{
    display: block !important;
    padding: 0px 0px 50px 0px !important;
  }
  .content-div2{
    width: 100%;
  }
  .heading-css2{
    font-size: 35px;
  }
  .heading-h4{
    font-size: 20px !important ;
  }
  .p-class2{
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  /* Section 1 */
  .hero-inner {
    display: block !important;
    padding: 50px 0px 50px 0px;
  }
  .content-div {
    width: 100% !important;
  }
  .heading-css {
    font-size: 30px;
  }
  .p-class {
    font-size: 16px;
  }
  .class-link {
    display: flex;
    justify-content: center;
  }
  .margin-top-5 {
    margin-top: 10px;
  }
  .image-div {
    margin-top: 25px;
  }

  /* Section 2 */
  .hero-inner2{
    display: block !important;
    padding: 0px 0px 50px 0px !important;
  }
  .content-div2{
    width: 100%;
  }
  .heading-css2{
    font-size: 35px;
  }
  .heading-h4{
    font-size: 18px;
  }
  .p-class2{
    font-size: 16px;
  }
}
