@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
@font-face {
  font-family: FontAwesome;
  src: url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3)
      format("embedded-opentype"),
    url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.woff2?v=4.6.3)
      format("woff2"),
    url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.woff?v=4.6.3)
      format("woff"),
    url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.ttf?v=4.6.3)
      format("truetype"),
    url(https://dce5jani6jm7e.cloudfront.net/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular)
      format("svg");
  font-weight: 400;
  font-style: normal;
}
body {
  font-weight: 400;
  color: #333;
  line-height: 1.375rem;
  font-family: sans-serif;
  font-size: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  font-smooth: never !important;
  -webkit-font-smoothing: antialiased !important;
  text-shadow: none !important;
  -webkit-text-stroke: 0;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container {
  max-width: 1040px;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px !important;
    max-width: 1200px !important;
  }

  .container {
    width: 1200px !important;
    max-width: 1200px !important;
  }

  .container {
    width: 1200px !important;
    max-width: 1200px !important;
    margin: 0 auto;
    position: relative;
  }
}

section {
  display: block;
}

section {
  padding: 25px 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
}

body {
  text-rendering: auto;
}

body {
  background-color: #fff;
}

body {
  font-weight: 400;
}

body {
  width: 100%;
}

body {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  color: #333;
  line-height: 1.375rem;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

body {
  font-family: Inter, "Open Sans", sans-serif;
  font-size: 1rem;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  position: relative;
}

body {
  margin: 0;
  font-family: Inter, Open Sans;
}

html {
  -webkit-tap-highlight-color: transparent;
}

html {
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: sans-serif;
}

html {
  width: 100%;
}

html {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

html {
  background-color: #fff;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 100%;
}

html {
  overflow: initial !important;
  scroll-behavior: smooth;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row {
  margin-top: 30px;
}

.row:first-child {
  margin-top: 0;
}

.row {
  margin-top: 0;
}

.row:last-child {
  margin-top: 0;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

#navbar.header-navigation-part:after,
.btn-group-vertical > .btn-group::after,
.btn-toolbar:after,
.calendar-wrapper .choice-color-box::after,
.check-list-with-img:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.custom-pagination-fancy:after,
.custom-pagination:after,
li > a,
.form-horizontal .form-group::after,
.header-top-part:after,
.icon-border:after,
.modal-footer:after,
.modal-header:after,
.nav-tabs.vertical-tabs:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar-nav:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after,
.slider-calc li::after,
.slider-panel:after,
.stat-breadcrumbs:after,
.testimonials-profile-switch:after,
.trigger-table-sub-accordion:after,
.trigger-table:after,
nav#navbar:after,
p {
  clear: both;
}

.clear:after,
.form-group:after,
.pagination:after,
.row:after,
dl:after,
header:after {
  clear: both;
  content: "";
  display: table;
}

@media (min-width: 768px) {
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
}

.text-center {
  text-align: center;
}

.p-y-lg {
  padding-top: 3em !important;
}

.p-y-lg {
  padding-bottom: 3em !important;
}

.p-y-lg {
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}

.online-trading-wrap > div {
  background-color: #f5f7fa;
}

#navbar.header-navigation-part::after,
.btn-group-vertical > .btn-group::after,
.btn-toolbar::after,
.calendar-wrapper .choice-color-box::after,
.check-list-with-img::after,
.clearfix::after,
.container-fluid::after,
.container::after,
.custom-pagination-fancy::after,
.custom-pagination::after,
.dropdown-menu > li > a,
.form-horizontal .form-group::after,
.header-top-part::after,
.icon-border::after,
.modal-footer::after,
.modal-header::after,
.nav-tabs.vertical-tabs::after,
.nav::after,
.navbar-collapse::after,
.navbar-header::after,
.navbar-nav::after,
.navbar::after,
.pager::after,
.panel-body::after,
.row::after,
.slider-calc li::after,
.slider-panel::after,
.stat-breadcrumbs::after,
.testimonials-profile-switch::after,
.trigger-table-sub-accordion::after,
.trigger-table::after,
nav#navbar::after,
p {
  clear: both;
}

.why-choose-am div {
  color: #fff;
}

.why-choose-am {
  background-color: #424559;
}

.m-b-md {
  margin-bottom: 1.5em !important;
}

.m-t-lg {
  margin-top: 3em !important;
}

.m-b-md {
  margin-bottom: 1.5em !important;
}

.m-t-lg {
  margin-top: 3em !important;
}

.m-t-md {
  margin-top: 1.5em !important;
}

.m-b-lg {
  margin-bottom: 3em !important;
}

.m-t-md {
  margin-top: 1.5em !important;
}

.m-b-lg {
  margin-bottom: 3em !important;
}

.col-sm-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }
}

.col-sm-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .col-sm-5 {
    float: left;
  }

  .col-sm-5 {
    width: 41.66667%;
  }
}

a {
  background-color: transparent;
}

a {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a {
  font-weight: 400;
}

a {
  color: #237dea;
  line-height: 1.1rem;
  -webkit-transition: 150ms;
  -moz-transition: 150ms;
  -o-transition: 150ms;
  transition: 150ms;
}

.button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  border: 1px solid transparent;
  -webkit-border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;
  border-radius: 2.5rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  height: 2.25em;
  line-height: 1.355;
  padding-bottom: -webkit-calc(0.375em - 1px);
  padding-bottom: -moz-calc(0.375em - 1px);
  padding-bottom: calc(0.375em - 1px);
  padding-top: -webkit-calc(0.375em - 1px);
  padding-top: -moz-calc(0.375em - 1px);
  padding-top: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  color: #424559;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.button {
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  box-shadow: none;
  display: -ms-inline-flexbox;
  -js-display: inline-flex;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  height: 2.25em;
  -ms-flex-pack: center;
  justify-content: flex-start;
  line-height: 1.355;
  padding: calc(0.375em - 1px) 0.75em;
  position: relative;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  color: #424559;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
}

.rounded-btn-success {
  background: #2ecc71;
  color: #fff;
  border: 1px solid #2ecc71;
}

.button.is-normal {
  height: 3rem;
}

a:active,
a:hover {
  outline: 0;
}

a:hover {
  text-decoration: none;
  outline: 0;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #1364c7;
  background: 0 0;
}

.theme-am .rounded-btn-success:active,
.theme-am .rounded-btn-success:hover {
  background: #26ab5f;
  color: #fff;
  border-color: #26ab5f;
  transition: all 0.3s ease;
}

.theme-am .rounded-btn-success:active,
.theme-am .rounded-btn-success:focus,
.theme-am .rounded-btn-success:hover {
  background: #26ab5f;
  color: #fff;
  border-color: #26ab5f;
  transition: all 0.3s ease;
}

h2 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 30px;
}

h2 {
  margin: 0 0 20px;
  padding: 0;
}

h2 {
  font-weight: 400;
  color: #404855;
}

h2 {
  font-size: 2.1rem;
  line-height: 2.5rem;
}

h2 {
  font-weight: 500;
}

h2 {
  line-height: 2.5rem;
  font-size: 2.1rem;
}

ul {
  margin-top: 0;
}

ul {
  margin-bottom: 10px;
}

ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  line-height: inherit;
}

ul {
  padding: 0;
  margin: 0 0 25px;
}

.ul-unstyle {
  text-align: left;
}

ul:last-child {
  margin-bottom: 0;
}

.text-white {
  color: #fff;
}

svg:not(:root) {
  overflow: hidden;
}

p:last-child {
  margin-bottom: 0;
}

li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  padding: 0;
  line-height: inherit;
}

ul li {
  margin: 0 0 10px;
}

.ul-unstyle li {
  margin-bottom: 15px;
}

.ul-unstyle li {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #424559;
  padding-left: 26px;
  position: relative;
}

.ul-unstyle.text-white li {
  color: #fff;
}

.fa,
.ul-unstyle li::before,
body,
html {
  -moz-osx-font-smoothing: grayscale;
}

.fa,
.ul-unstyle li::before,
body {
  text-rendering: auto;
}

.ul-unstyle li::before {
  content: "\f00c";
  position: absolute;
  left: 0;
  top: 6px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
}

.fa,
.ul-unstyle li::before,
body,
html {
  -moz-osx-font-smoothing: grayscale;
}

.fa,
.ul-unstyle li::before,
body {
  text-rendering: auto;
}

ul li:last-of-type {
  margin-bottom: 0;
}

.ul-unstyle li,
.ul-unstyle li:last-child {
  margin-bottom: 15px;
}

/* These were inline style tags. Uses id+class to override almost everything */
#cy-products-open-account-1.style-bbYis {
  min-width: 200px;
  margin-right: 15px;
  margin-bottom: 15px;
}
