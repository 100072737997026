@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
}
body {
  /* CSS Variables that may have been missed get put on body */
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-fg-scale: 1;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
body {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
html {
  box-sizing: border-box;
}

html {
  overflow: initial !important;
  scroll-behavior: smooth;
}

.try-demo__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  flex-direction: column;
  height: inherit;
}

@media screen and (min-width: 768px) {
  .try-demo__wrapper {
    flex-direction: row;
  }
}

*,
:after,
:before,
body,
html {
  box-sizing: border-box;
}

.try-demo__info {
  max-width: 500px;
  width: 100%;
}

.try-demo__cards {
  max-width: 500px;
  width: 100%;
  
}

h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
}

.home-page h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 24px;
  font-weight: 400;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.87);
}

.home-page h2 {
  color: rgba(0, 0, 0, 0.87);
}

p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 24px;
  margin-top: 0;
}

a {
  background-color: transparent;
}

a {
  color: #237dea;
  text-decoration: none;
}

.action-link {
  margin-top: 8px;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: inherit;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  padding: 0 8px 0 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 4px;
}

.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-fg-scale: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-button {
  height: 36px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-family: Inter;
}

.mdc-button--large {
  padding: 0 24px 0 24px;
  height: 48px;
}

.mdc-button:not(:disabled) {
  background-color: transparent;
}

.mdc-button:not(:disabled) {
  color: #105ef6;
  color: var(--mdc-theme-primary, #105ef6);
}

.mdc-button--unelevated:not(:disabled) {
  background-color: #105ef6;
}

.mdc-button--unelevated:not(:disabled) {
  background-color: var(--mdc-theme-primary, #105ef6);
}

.mdc-button--unelevated:not(:disabled) {
  color: hsla(0, 0%, 100%, 0.87);
  color: var(--mdc-theme-on-primary, hsla(0, 0%, 100%, 0.87));
}

.mdc-button--color-positive:not(:disabled) {
  color: #23b064;
  background-color: transparent;
}

.mdc-button--color-positive.mdc-button--unelevated:not(:disabled) {
  color: hsla(0, 0%, 100%, 0.87);
  background-color: #23b064;
}

.mdc-button:hover {
  cursor: pointer;
}

.try-demo__cards-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdc-button .mdc-button__ripple {
  border-radius: 4px;
}

.mdc-button .mdc-button__ripple {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button .mdc-button__ripple::after,
.mdc-button .mdc-button__ripple::before {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-button .mdc-button__ripple::before {
  -webkit-transition: opacity 15ms linear, background-color 15ms linear;
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-button .mdc-button__ripple::after,
.mdc-button .mdc-button__ripple::before {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.mdc-button .mdc-button__ripple::after,
.mdc-button .mdc-button__ripple::before {
  background-color: #105ef6;
}

.mdc-button .mdc-button__ripple::after,
.mdc-button .mdc-button__ripple::before {
  background-color: var(--mdc-theme-primary, #105ef6);
}

.mdc-button--raised .mdc-button__ripple::after,
.mdc-button--raised .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after,
.mdc-button--unelevated .mdc-button__ripple::before {
  background-color: hsla(0, 0%, 100%, 0.87);
}

.mdc-button--raised .mdc-button__ripple::after,
.mdc-button--raised .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after,
.mdc-button--unelevated .mdc-button__ripple::before {
  background-color: var(--mdc-theme-on-primary, hsla(0, 0%, 100%, 0.87));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button:hover .mdc-button__ripple::before {
  opacity: 0.04;
}

.mdc-button--raised:hover .mdc-button__ripple::before,
.mdc-button--unelevated:hover .mdc-button__ripple::before {
  opacity: 0.08;
}

.try-demo__cards-item {
  width: 124px;
  height: 124px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.04),
    0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8px 8px;
}

.try-demo__cards-item.centered {
  width: 156px;
  height: 156px;
  margin-right: -10px;
  margin-left: -10px;
  z-index: 1;
}

.try-demo__cards-item-image {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p:last-child {
  margin-bottom: 0;
}

.try-demo__cards-item p {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
}

.try-demo__cards-item.centered .try-demo__cards-item-image {
  width: 64px;
  height: 64px;
  margin-top: 26px;
}

.try-demo__cards-item.centered p {
  font-size: 1rem;
}

img {
  border-style: none;
}

.try-demo__cards-item-image img {
  width: 40px;
  height: 40px;
}

.try-demo__cards-item.centered img {
  width: 42px;
  height: 48px;
}

.section-6 {
  background-image: url("https://dynamic-images.admiralmarkets.com/2560x,webp/static-images.admiralmarkets.com/images/elements/try-demo.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 328px;
  
}
