@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap");
body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
body {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
html {
  box-sizing: border-box;
}

html {
  overflow: initial !important;
  scroll-behavior: smooth;
}

:root {
  --adm-background-default: #f8f8fb;
  --adm-text-title: #181e30;
  --adm-text-primary: #293050;
  --adm-text-on-color-default: #fff;
  --adm-button-primary-default: #0080ff;
  --adm-button-primary-hover: #0070ed;
}

.container {
  max-width: 1472px;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
}

.adaptive-hero-banner__container {
  padding-top: 24px;
  padding-bottom: 64px;
}

@media screen and (min-width: 768px) {
  .adaptive-hero-banner__container {
    display: flex;
    align-items: center;
    padding-top: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .adaptive-hero-banner__container {
    padding-top: 48px;
  }
}

@media screen and (min-width: 1280px) {
  .adaptive-hero-banner__container {
    padding-top: 64px;
  }
}

*,
:after,
:before,
body,
html {
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .adaptive-hero-banner__item {
    width: 50%;
  }
}

.adm-text--primary {
  color: var(--adm-text-primary);
}

.adm-typography {
  margin: 0;
  padding: 0;
}

.adm-typography--bodyL {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.18px;
}

.adaptive-hero-banner__subtitle {
  margin-bottom: 8px;
}

@media screen and (min-width: 1024px) {
  .adaptive-hero-banner__subtitle {
    margin-bottom: 16px;
  }
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
}

h1 {
  font-size: 3rem;
  line-height: 3.5rem;
}

.adm-text--title {
  color: var(--adm-text-title);
}

.adm-typography--displayL {
  font-family: Inter, sans-serif;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 800;
  letter-spacing: -0.15px;
}

@media (min-width: 1024px) {
  .adm-typography--displayL {
    font-family: Inter, sans-serif;
    font-size: 48px;
    line-height: 1.25;
    font-weight: 800;
    letter-spacing: -0.25px;
  }
}

.adaptive-hero-banner__title {
  margin-bottom: 16px;
}

.adaptive-hero-banner__description {
  margin-bottom: 32px;
}

@media screen and (min-width: 1024px) {
  .adaptive-hero-banner__description {
    margin-bottom: 48px;
  }
}

.adaptive-hero-banner__action {
  display: flex;
}

p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 24px;
  margin-top: 0;
}

.adaptive-hero-banner__description * {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.18px;
}

p:last-child {
  margin-bottom: 0;
}

.adaptive-hero-banner__description p {
  margin-bottom: 16px;
}

.adaptive-hero-banner__description * {
  color: var(--adm-text-primary);
}

img {
  border-style: none;
}

.adaptive-hero-banner__image img {
  display: block;
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 24px auto auto;
}

@media screen and (min-width: 768px) {
  .adaptive-hero-banner__image img {
    max-width: 100%;
    margin: 0;
  }
}

a {
  background-color: transparent;
}

a {
  color: #237dea;
  text-decoration: none;
}

.adm-button {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background: none;
  transition-property: background-color, color, outline-width;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.adm-button.adm-button--primary-default {
  color: var(--adm-text-on-color-default);
  background-color: var(--adm-button-primary-default);
}

.adm-button.adm-button--inline {
  display: inline-flex;
}

.adm-button.adm-button--large {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.18px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.adm-button.adm-button--large.adm-button--without-icon {
  padding-left: 16px;
  padding-right: 16px;
}

.adm-button.adm-button--primary-default:hover {
  background-color: var(--adm-button-primary-hover);
}
